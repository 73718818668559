import { Collapse } from "antd"

/**
 * Component for hiding/showing content, using the ant design component {@link Collapse}
 * @param {object} props
 * @param {boolean} props.show 
 * @param {React.CSSProperties} props.style
 * @param {React.ReactNode} props.children 
 * @returns
 */
function CollapseContent({ show = true, style = {}, children = <></> }) {

    return (
        <Collapse activeKey={ show ? '1' : '0' } ghost style={ style } items={ [{
            key: "1",
            showArrow: false,
            className: "collapseHideHeader",
            children: children
        }] } />
    )
}

export default CollapseContent
