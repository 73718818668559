import { alertActions } from './alert.actions';
import { codeService } from '../_services/code.service';
import { codeConstants } from '../_constants/code.constants';
import { errorHandler } from '../_helpers/errorHandler';

export const codeActions = {
    deleteOldOneTimeCodes,
    generateCode,
    updateUserCodes,
    clear
};

function deleteOldOneTimeCodes(keypadId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request({ keypadId }));
            codeService.deleteOldOneTimeCodes(keypadId)
                .then(
                    result => {
                        dispatch(success(result));

                        // dispatch(alertActions.success(result.message));
                        resolve(true);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject();
                    }
                );
        }).catch(e => { });
    };

    function request(config) { return { type: codeConstants.DELETE_OLD_ONE_TIME_CODES_REQUEST, config } }
    function success(result) { return { type: codeConstants.DELETE_OLD_ONE_TIME_CODES_SUCCESS, result } }
    function failure(error) { return { type: codeConstants.DELETE_OLD_ONE_TIME_CODES_FAILURE, error } }
}

function generateCode(config) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request({ config }));
            codeService.generateCode(config)
                .then(
                    result => {
                        if (result.data) {
                            dispatch(success(result));
                            // dispatch(alertActions.success(result.message));
                        } else {
                            dispatch(failure(result.message));
                            dispatch(alertActions.error(result.message));
                        }
                        resolve(result);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject(error);
                    }
                );
        }).catch(e => { });
    };

    function request(config) { return { type: codeConstants.GENERATE_CODE_REQUEST, config } }
    function success(config) { return { type: codeConstants.GENERATE_CODE_SUCCESS, config } }
    function failure(error) { return { type: codeConstants.GENERATE_CODE_FAILURE, error } }
}

function updateUserCodes(keypadId, userCodes) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request({ keypadId, userCodes }));
            codeService.updateUserCodes(keypadId, userCodes)
                .then(
                    result => {
                        dispatch(success(result));
                        dispatch(alertActions.success(result.message));
                        resolve(result);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject();
                    }
                );
        }).catch(e => { });
    };

    function request(config) { return { type: codeConstants.UPDATE_USERCODES_REQUEST, config } }
    function success(data) { return { type: codeConstants.UPDATE_USERCODES_SUCCESS, data } }
    function failure(error) { return { type: codeConstants.UPDATE_USERCODES_FAILURE, error } }
}

function clear() { return { type: codeConstants.CLEAR_CODE } }
