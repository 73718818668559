import { serviceWorkerConstants } from '../_constants/serviceWorker.constants';

export const serviceWorkerActions = {
    setState
};

function setState(state) {
    return dispatch => {
        dispatch({ type: serviceWorkerConstants.SW_SET_STATE, state: state });
    };
}
