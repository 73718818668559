import { getToken } from "../../helpers/sessionHelper";

export function authHeader() {
    // return authorization header with jwt token
    let token = getToken();

    if (token) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}