import { getUser } from "../../helpers/sessionHelper";
import { keypadConstants } from "../_constants/keypad.constants";
import { errorHandler } from "../_helpers/errorHandler";
import { keypadService } from "../_services/keypad.service";
import { alertActions } from "./alert.actions";

export const keypadActions = {
    getAll,
    updateKeypad,
    registerKeypad,
    deleteKeypad,
    clear
};

function getAll() {
    const user = getUser();
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            keypadService.getAll(user.userId)
                .then(
                    result => {
                        // dispatch(alertActions.success(result.message));
                        dispatch(success(result.body));
                        resolve(result.body)
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject();
                    }
                );
        }).catch(e => { });
    };

    function request() { return { type: keypadConstants.GET_ALL_REQUEST } }
    function success(devices) { return { type: keypadConstants.GET_ALL_SUCCESS, devices } }
    function failure(error) { return { type: keypadConstants.GET_ALL_FAILURE, error } }
}

function updateKeypad(keypad) {
    let userSeed = keypad.keypadSeed ? { userSeed: keypad.keypadSeed } : null;
    const newKeypad = {
        keypadOwner: keypad.keypadOwner,
        keypadId: keypad.keypadId,
        keypadCustomName: keypad.keypadCustomName,
        keypadType: keypad.keypadType,
        keypadLocker: keypad.keypadLocker,
        keypadServiceConfCode: keypad.keypadServiceConfCode,
        keypadUserConfCode: keypad.keypadUserConfCode,
        keypadSilent: keypad.keypadSilent,
        keypadHigSecurity: keypad.keypadHigSecurity,
        keypadFreeLocker: keypad.keypadFreeLocker,
        keypadComment: keypad.keypadComment,
        ...userSeed
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request({ newKeypad }));
            keypadService.updateKeypad(newKeypad)
                .then(
                    result => {
                        dispatch(success(result));
                        dispatch(alertActions.success(result.message));
                        resolve(result);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject(error);
                    }
                );
        }).catch(e => { });
    };

    function request(device) { return { type: keypadConstants.UPDATE_KEYPAD_REQUEST, device } }
    function success(result) { return { type: keypadConstants.UPDATE_KEYPAD_SUCCESS, result } }
    function failure(error) { return { type: keypadConstants.UPDATE_KEYPAD_FAILURE, error } }
}

function registerKeypad(keypad) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request({ keypad }));
            keypadService.registerKeypad(keypad)
                .then(
                    result => {
                        if (result.success) {
                            dispatch(success(result));
                            dispatch(alertActions.success(result.message));
                        } else{
                            dispatch(failure(result));
                            dispatch(alertActions.error(result.message));
                        }
                        resolve(keypad);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject();
                    })
        }).catch(e => {});
    };

    function request(device) { return { type: keypadConstants.REGISTER_KEYPAD_REQUEST, device } }
    function success(result) { return { type: keypadConstants.REGISTER_KEYPAD_SUCCESS, result } }
    function failure(error) { return { type: keypadConstants.REGISTER_KEYPAD_FAILURE, error } }
}

function deleteKeypad(keypad) {
    keypad = {
        keypadId: keypad.keypadId
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            keypadService.deleteKeypad(keypad)
                .then(
                    result => {
                        dispatch(success());
                        dispatch(alertActions.success(result.message));
                        resolve(result)
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject(error);
                    })
        }).catch(e => { });
    };

    function request() { return { type: keypadConstants.DELETE_KEYPAD_REQUEST } }
    function success() { return { type: keypadConstants.DELETE_KEYPAD_SUCCESS } }
    function failure(error) { return { type: keypadConstants.DELETE_KEYPAD_FAILURE, error } }
}

function clear() { return { type: keypadConstants.CLEAR_KEYPAD } }
