export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    GET_USER_INFO_REQUEST: "GET_USER_INFO_REQUEST",
    GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
    GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",

    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

    UPDATE_INFO_REQUEST: "UPDATE_INFO_REQUEST",
    UPDATE_INFO_SUCCESS: "UPDATE_INFO_SUCCESS",
    UPDATE_INFO_FAILURE: "UPDATE_INFO_FAILURE",

    FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    
    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    UPDATE_TOUR_STATE_REQUEST: 'UPDATE_TOUR_STATE_REQUEST',
    UPDATE_TOUR_STATE_SUCCESS: 'UPDATE_TOUR_STATE_SUCCESS',
    UPDATE_TOUR_STATE_FAILURE: 'UPDATE_TOUR_STATE_FAILURE',
    

    CHECK_EMAIL_REQUEST: 'CHECK_EMAIL_REQUEST',
    CHECK_EMAIL_SUCCESS: 'CHECK_EMAIL_SUCCESS',
    CHECK_EMAIL_FAILURE: 'CHECK_EMAIL_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    UPDATE_AUTH: 'UPDATE_AUTH',
    
    CLEAR_AUTHENTICATION: "CLEAR_AUTHENTICATION",
    CLEAR_REGISTER: "CLEAR_REGISTER",
    CLEAR_USER: "CLEAR_USER"
};
