const userValidation = {
    email: [
        {
            required: true,
            message: "Please input your email"
        },
        {
            type: "email",
            message: "Not a valid email"
        }
    ],
    password: [
        {
            required: true,
            message: "Please input your password"
        },
        {

            min: 6,
            message: "Password must be at least 6 characters"
        }
    ],
    userName: [
        {
            required: true,
            message: "Please input your username"
        },
        {
            min: 2,
            message: "Username must be at least 2 characters"
        },
        {
            pattern: new RegExp(/^(?!.*; ).*/),
            message: "'; ' not allowed"
        }
    ],
    firstName: [
        {
            required: true,
            message: "Please input your first name"
        }
    ]
}

export default userValidation;