import styles from "./pageNotFound.module.css";
import React from 'react'

function PageNotFound() {

    return (
        <div className={ styles.wrapper }>
            <h1>Page not found</h1>
        </div>
    );
}

export default PageNotFound;