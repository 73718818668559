import { ShareAltOutlined } from "@ant-design/icons";
import { Modal, Space } from "antd";
import { useState } from "react";
import styles from "./share.module.css";

const Share = ({ code, body, lockerName }) => {
    const [isOpen, setIsOpen] = useState(false);

    let subject = `Access granted to lock: ${lockerName}`;
    return (
        <>
            <ShareAltOutlined onClick={ () => setIsOpen(true) } />

            <Modal title={ "Share the code: " + code } width={ 300 } zIndex={1900} open={ isOpen } className={ styles.shareModal } footer={ false } onCancel={ () => setIsOpen(false) }>
                <Space direction='vertical'>
                    <a href={ `sms:?&body=${body}` }>Send as SMS</a>
                    <a href={ `mailto:?subject=${subject}&body=${body}` } target="_blank" rel="noopener noreferrer">Send as Email</a>
                </Space>
            </Modal>
        </>
    )
}

export default Share;