import Icon from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import styles from "./nav.module.css";
import { ReactComponent as device } from "../../assets/images/KeypadInvertv2.svg";
import { ReactComponent as blink } from "../../assets/images/blink.svg";
import { ReactComponent as more } from "../../assets/images/more.svg";
import { callFunctionActions } from "../../api/_actions/callFunction.actions";
import { useDispatch } from "react-redux";
import { navigate, toggleBlink } from "../../helpers/navigationHelper";

const Nav = () => {
    const dispatch = useDispatch();

    const location = useLocation()

    const {
        activationCodesIsOpen,
        blinkIsOpen,
        lockerConfigIsOpen,
    } = location.state || {}

    function onClickBlink(e) {

        if (location.pathname.startsWith('/lockers')) {
            e.preventDefault();
            if (blinkIsOpen) {
                navigate(-1)
            }
            else if (activationCodesIsOpen) {
                dispatch(callFunctionActions.call("activationCodesSaveCodes"));
            }
            else if (lockerConfigIsOpen) {
                dispatch(callFunctionActions.call("lockerConfigurationSubmit"));
            }
            else {
                toggleBlink(true)
            }
        }
    }

    return (
        <div className={ styles.nav }>
            <Link className={ [styles.link, "nav-devices"].join(" ") } to="/devices" state={ {} }>
                <div className={ styles.icon }> <Icon component={ device } /></div>
                <span className={ styles.text }>DEVICES</span>
            </Link>
            <Link className={ [styles.link, "nav-blink"].join(" ") } to="/lockers" onClick={ onClickBlink } state={ {} }>
                <div className={ styles.icon }> <Icon component={ blink } /></div>
                <span className={ styles.text }> { location.pathname.startsWith('/lockers') ? "BLINK" : "HOME" }</span>
            </Link>
            <Link className={ [styles.link, "nav-more"].join(" ") } to="/user-settings" state={ {} }>
                {/* <Badge count={ 1 } offset={ [10, 10] }> */ }
                <div className={ styles.icon }> <Icon component={ more } /></div>
                {/* </Badge> */ }
                <span className={ styles.text }>MORE</span>
            </Link>
        </div>
    )
}

export default Nav;