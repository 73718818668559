import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import store from "./api/_helpers/store"
import { serviceWorkerActions } from './api/_actions/serviceWorker.actions';
import { ConfigProvider } from 'antd';
import "antd/dist/reset.css"

const root = ReactDOM.createRoot(
    document.getElementById('root')
)

root.render(
    <Provider store={ store }>
        <ConfigProvider theme={
            {
                token: {
                    fontSize: 16,
                    fontSizeLG: 15,
                    colorPrimary: "black",
                    borderRadius: 5,
                    colorBorder: "black",
                    lineWidth: 1,
                    controlItemBgActive: "#ccc",
                    colorLink: "black",
                    colorLinkActive: "black",
                    colorLinkHover: "black",
                },
                components: {
                    Button: {
                        controlHeightSM: 26,
                        controlHeight: 34,
                        controlHeightLG: 52,
                        colorTextDisabled: "white",
                        colorBgContainerDisabled: "#666",
                        controlOutline: 0,
                        borderRadiusLG: 5,
                        paddingContentVerticalLG: 10.9,
                        fontSize: 15,
                    },
                    Collapse: {
                        colorBorder: "#ccc"
                    },
                    DatePicker: {
                        borderRadius: 2,
                        controlOutline: "rgba(0, 0, 0, .2)",
                    },
                    Drawer: {
                        padding: 12,
                        paddingLG: 12,
                        fontSize: 19,
                        fontSizeLG: 19,
                    },
                    Input: {
                        paddingContentHorizontalSM: 10,
                        paddingContentHorizontalLG: 10,
                        paddingContentVerticalSM: 2,
                        paddingContentVerticalLG: 10,
                        borderRadius: 2,
                        paddingLG: 10,
                        controlOutline: "rgba(0, 0, 0, .2)",
                    },
                    
                    Select: {
                        controlItemBgActive: "#ccc",
                        borderRadius: 2,
                        controlOutline: "rgba(0, 0, 0, .2)",
                    },
                    Switch: {
                        fontSize: 15
                    },
                }
            } }>
            <App />
        </ConfigProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    setState: (state) => store.dispatch(serviceWorkerActions.setState(state))
});
