import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

function SwipeableLink({ onClick, linkRef = null, ...props }) {
    const state = useRef({ x: 0 });
    const handleMouseDown = e => {
        state.current.x = e.screenX;
    };
    const handleClick = e => {
        const delta = Math.abs(e.screenX - state.current.x);
        if (delta > 10) {
            e.preventDefault();
        } else {
            if (onClick) {
                e.preventDefault();
            }
            onClick();
        }
    };

    return (
        <Link { ...props } itemRef={ linkRef } ref={linkRef} onMouseDown={ handleMouseDown } onClick={ handleClick } />
    );
};
export default SwipeableLink;