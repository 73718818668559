import { callFunctionConstants } from "../_constants/callFunction.constants";

export function callFunction(state = {}, action) {
    switch (action.type) {
        case callFunctionConstants.CALL:
            return {
                function: action.message
            };
        case callFunctionConstants.CLEAR_FUNCTION:
            return {};
        default:
            return state
    }
}