import { codeConstants } from '../_constants/code.constants';

export function codes(state = {}, action) {
    switch (action.type) {

        case codeConstants.DELETE_OLD_ONE_TIME_CODES_REQUEST:
            return {
                deleteOldOneTimeCodesRequest: true
            }
        case codeConstants.DELETE_OLD_ONE_TIME_CODES_SUCCESS:
            return {
                deleteOldOneTimeCodesSuccess: action.result
            }
        case codeConstants.DELETE_OLD_ONE_TIME_CODES_FAILURE:
            return {
                deleteOldOneTimeCodesFailure : action.error
            }

        // #region GENERATE_CODE
        case codeConstants.GENERATE_CODE_REQUEST:
            return {
                generateCodeRequest: true
            };
        case codeConstants.GENERATE_CODE_SUCCESS:
            return {
                generatedCode: action.config.data
            };
        case codeConstants.GENERATE_CODE_FAILURE:
            return {
                error: action.error
            };
        // #endregion

        // #region UPDATE_USERCODES
        case codeConstants.UPDATE_USERCODES_REQUEST:
            return {
                updateUsercodesRequest: true
            };
        case codeConstants.UPDATE_USERCODES_SUCCESS:
            return {
                updateUsercodesSuccess: action.data.success
            };
        case codeConstants.UPDATE_USERCODES_FAILURE:
            return {
                error: action.error
            };
        // #endregion

        case codeConstants.CLEAR_CODE:
            return {};


        default:
            return state
    }
}