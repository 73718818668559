import { authHeader } from '../_helpers/auth-header';
import handleResponse from '../_helpers/responseHandler';

export const codeService = {
    deleteOldOneTimeCodes,
    generateCode,
    updateUserCodes,
};
let url = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PRODUCTION

function deleteOldOneTimeCodes(keypadId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authHeader() }
    };

    return fetch(`${url}/code/delete-old-one-time-codes/${keypadId}`, requestOptions).then(handleResponse);
}

function generateCode(config) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(config)
    };

    return fetch(`${url}/code/generate`, requestOptions).then(handleResponse);
}

function updateUserCodes(keypadId, codes) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({ userCodes: codes })
    };

    return fetch(`${url}/code/usercodes/${keypadId}`, requestOptions).then(handleResponse);
}
