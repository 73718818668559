

export function errorHandler(dispatch, error, failure, alert) {
    if (error.success) {
        
        dispatch(failure(error.message));
        dispatch(alert(error.message));

    } else if(!error.success) {
        dispatch(failure(error));
        dispatch(alert(error.message));
        
    } else{
        dispatch(failure("Unexpected error"));
        dispatch(alert("Unexpected error"));
        
    }
}