import { userActions } from "../../api/_actions/user.actions";
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Drawer, Form, Input } from "antd";
import styles from "./forgotPassword.module.css";
import rules from "../../helpers/validation/userValidation";
import { useStateCallback } from "../../hooks/useStateCallback";
import { navigate } from "../../helpers/navigationHelper";

function ForgotPassword() {
    const dispatch = useDispatch();
    const userState = useSelector(state => state.users);

    const [message, setMessage] = useStateCallback({ success: null, message: null })
    const [emailFound, setEmailFound] = useState(false);
    const [passwordResat, setPasswordResat] = useState(false);
    const [email, setEmail] = useState("");

    const [form] = Form.useForm();

    useEffect(() => {
        setMessage({
            success:
                userState.forgotPasswordSuccess?.success ||
                userState.forgotPasswordFailure?.success ||
                userState.checkEmailSuccess?.success ||
                userState.checkEmailFailure?.success,
            message:
                userState.forgotPasswordSuccess?.message ||
                userState.forgotPasswordFailure?.message ||
                userState.checkEmailSuccess?.message ||
                userState.checkEmailFailure?.message,
        })
    }, [userState.forgotPasswordSuccess, userState.forgotPasswordFailure, userState.checkEmailSuccess, userState.checkEmailFailure, setMessage])

    function checkEmail({ email }) {
        dispatch(userActions.checkEmail(email)).then(result => {
            if (result.success)
                setEmailFound(true);
        })
    }

    function forgotPassword() {
        dispatch(userActions.forgotPassword(email)).then(result => {
            if (result.success)
                setPasswordResat(true);
        })
    }

    return (
        <>
            <Drawer
                closeIcon={ <LeftOutlined /> }
                bodyStyle={ { padding: 0 } }
                contentWrapperStyle={ { width: "100%", padding: "0px", boxShadow: "none" } }
                getContainer="#root .content"
                open
                mask={ null }
                motion={ null }
                keyboard={ false }
                title="Find your account"
                onClose={ () => navigate("/login") }
            >

                <div className={ styles.wrapper }>
                    { message.message &&
                        <Alert type={ message.success ? "success" : "error" } message={ message.message } />
                    }

                    <Form form={ form } onFinish={ emailFound ? forgotPassword : checkEmail } validateTrigger="onSubmit" >

                        { passwordResat ?
                            <></>
                            :
                            emailFound ?

                                <>
                                    <Button type="primary" block size='large' name='login' htmlType='submit' loading={ userState.forgotPasswordRequest }>
                                        RESET PASSWORD
                                    </Button>
                                </>
                                :
                                <>
                                    <p>Please enter your email to search for your account</p>
                                    <Form.Item rules={ rules.email } name="email" >
                                        <Input placeholder="Email" size='large' inputMode='email' onChange={ e => setEmail(e.target.value) } />
                                    </Form.Item>
                                    <Button type="primary" block size='large' name='login' htmlType='submit' loading={ userState.checkEmailRequest }>
                                        SEARCH
                                    </Button>
                                </>

                        }

                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default ForgotPassword;