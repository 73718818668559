import styles from "./main.module.css";
import { Button } from 'antd';
import React from 'react';
import logo from "../../assets/images/SALTO_inspired_access_logo.png";
import locker from "../../assets/images/XS4-code-locker-1.png";
import { navigate } from '../../helpers/navigationHelper';

function Home() {

    const login = () => { navigate("/login"); };
    const signUp = () => { navigate("/sign-up"); };

    return (
        <div className={ styles.wrapper }>
            <div className={ styles.logo }>
                <img src={ locker } alt="XS4 Code Locker" />
                <img src={ logo } alt="Conlan Salto Group Company" />
            </div>
            <div className={ styles.buttons }>
                <Button size='large' onClick={ login }>LOG IN</Button>
                <Button size='large' onClick={ signUp } type='primary' >SIGN UP</Button>
            </div>
        </div>
    );
}

export default Home;