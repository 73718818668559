import { FilterOutlined, LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useEffect, useRef, useState } from "react";
import ActivationCodes from "../../pages/Lockers/Locker/ActivationCodes/activationCodes";
import styles from "./activationCodesDrawer.module.css";
import { useLocation } from "react-router-dom";
import { navigate } from "../../helpers/navigationHelper";
import useSortDrawer from "../../hooks/useSortDrawer";

function ActivationCodesDrawer({ device, setDevice }) {
    const [searching, setSearching] = useState(false);
    const [searchString, setSearchString] = useState("");
    const searchInputRef = useRef();
    
    const {
        activationCodesIsOpen
    } = useLocation().state || {}
    
    const sortOptions = [
        {
            name: "Name",
            prop: "name",
            order: "asc"
        },
        {
            name: "Registration date",
            prop: "timestamp",
            order: "des"
        }
    ]
    const [sortBy, setSortBy] = useState(sortOptions[1]);
    const [sortDrawer, openSortDrawer] = useSortDrawer(sortOptions, setSortBy)

    useEffect(() => {
        if (searching)
            searchInputRef.current.focus()
    }, [searching]) // eslint-disable-line

    function resetSearching() {
        setSearching(false);
        setSearchString("");
    }

    const drawerTitle = () => searching
        ? <input placeholder='Search' className={ styles.searchInput } defaultValue={ searchString } ref={ searchInputRef }
            onChange={ (e) => setSearchString(e.target.value) } />
        : <>Activation Codes</>

    const drawerExtra = () =>
        <div className={ styles.options }>
            <SearchOutlined onClick={ () => setSearching(prev => !prev) } />
            {/* <DeleteOutlined />  Might be used later */ }
            <FilterOutlined onClick={ () => openSortDrawer()} /> 
            { sortDrawer }
        </div>

    return (
        <Drawer
            push={ false }
            destroyOnClose={ true }
            getContainer="#root .content"
            className={ styles.drawer }
            closeIcon={ <LeftOutlined /> }
            open={ activationCodesIsOpen }
            contentWrapperStyle={ { width: "100%", padding: "0px" } }
            bodyStyle={ { padding: "0px" } }
            title={ drawerTitle() }
            extra={ drawerExtra() }
            onClose={ () => {
                resetSearching()
                navigate(-1)
            } }
        >
            <ActivationCodes
                sortBy={ sortBy }
                searchString={ searchString }
                resetSearching={ resetSearching }
                device={ device } setDevice={ setDevice }
            />
        </Drawer>
    )
}

export default ActivationCodesDrawer;