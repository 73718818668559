import { getUser } from "../../helpers/sessionHelper";

function getOptions () { 
    let user = getUser();

    let isDeveloper = user.developer;
    
    const options = [
        {
            value: 1,
            label: "Salto XS4 Locker Lock LX050"
        }, {
            value: 2,
            label: "Salto XS4 LE7S05"
        }, {
            value: 3,
            label: "Custom",
            disabled: !isDeveloper
        }, {
            value: 4, // TODO Change in next revision, update value
            label: "Key Lock Box"
        }
    ]

    return options;
};

export default getOptions;