import { authHeader } from '../_helpers/auth-header';
import handleResponse from '../_helpers/responseHandler';

export const keypadService = {
    getAll,
    updateKeypad,
    registerKeypad,
    deleteKeypad
};
let url = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PRODUCTION

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() }
    };

    return fetch(`${url}/keypad`, requestOptions).then(handleResponse);
}

function updateKeypad(keypad) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(keypad)
    };

    return fetch(`${url}/keypad/${keypad.keypadId}`, requestOptions).then(handleResponse);
}

function registerKeypad(keypad) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({
            keypadType: "C1000LP",
            ...keypad
        })
    };

    return fetch(`${url}/keypad`, requestOptions).then(handleResponse);
}

function deleteKeypad(keypad) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    };

    return fetch(`${url}/keypad/${keypad.keypadId}`, requestOptions).then(handleResponse);
}
