import store from "../api/_helpers/store";

const TOKEN_KEY = 'authToken';
const REMEMBER_EMAIL = 'rememberEmail';

// Cookie expiration and more:
// https://www.cookiestatus.com/

const getFutureDateUTC = () => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 5);
    return date.toUTCString();
}

const getPastDateUTC = () => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    return date.toUTCString();
}

const saveCookie = (cookieName, value) => {
    let date = getFutureDateUTC();
    document.cookie = `${cookieName}=${JSON.stringify(value)}; expires=${date}; path=/; samesite=strict`;
}

const getValueFromCookieString = (cookieString, cookieName) => {
    cookieString = cookieString.replace(cookieName + "=", "");
    cookieString = cookieString.substring(1, cookieString.length - 1);
    return cookieString;
}

const getCookieValue = (cookieName) => {
    let cookieStrings = document.cookie.split("; ");
    let cookieString = cookieStrings.find(cookieString => cookieString.match(cookieName));
    if (cookieString) {
        return getValueFromCookieString(cookieString, cookieName);
    }
}

export function renewCookies() {
    [TOKEN_KEY, REMEMBER_EMAIL].forEach(cookieName => {
        let value = getCookieValue(cookieName);
        if (value) {
            saveCookie(cookieName, value);
        }
    })
}

export function deleteCookies() {
    let date = getPastDateUTC();
    [TOKEN_KEY].forEach(key => {
        document.cookie = `${key}=""; expires=${date}; path=/`;
    })
}

export const saveRememberEmail = (email) => saveCookie(REMEMBER_EMAIL, email);
export const saveToken = (token) => saveCookie(TOKEN_KEY, token);

export const getRememberEmail = () => getCookieValue(REMEMBER_EMAIL);
export const getToken = () => getCookieValue(TOKEN_KEY);

export const getUser = () => store.getState()?.authentication?.user;
