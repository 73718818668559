import { userActions } from '../../api/_actions/user.actions';
import React, { useEffect } from 'react';
import rules from "../../helpers/validation/userValidation.js";
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import styles from "./signUp.module.css";
import { Link } from 'react-router-dom';
import FullScreenSpinner from '../../components/FullscreenSpinner/fullscreenSpinner';
import Icon from '@ant-design/icons';
import { ReactComponent as ExternalLink } from "../../assets/images/externalLink.svg"

function SignUp() {
    const [form] = Form.useForm();

    const accept = Form.useWatch("accept", form);
    const password = Form.useWatch("password", form);
    // const confirmPassword = Form.useWatch("confirmPassword", form);

    const dispatch = useDispatch();
    const registeringState = useSelector(state => state.registration);

    useEffect(() => {
        dispatch(userActions.clearAll());
    }, [dispatch])

    function submit({ accept, ...user }) {
        dispatch(userActions.register(user)).then((result) => {
            if (result.success) {
                dispatch(userActions.login(user.email, user.password));
            }
        })
    }

    return (
        <div className={ styles.wrapper }>
            <Link to="/" className={ styles.back } ><ArrowLeftOutlined /></Link>
            <h1 className={ styles.header }>Sign up</h1>

            { registeringState.registerFailure &&
                <Alert type={ registeringState.registerFailure?.success ? null : "error" } message={ registeringState.registerFailure?.message } />
            }

            <Form className={ styles.form } form={ form } onFinish={ submit } validateTrigger="onSubmit">
                <Form.Item className={ styles.input } rules={ rules.email } name="email" >
                    <Input placeholder="Email" size='large' inputMode='email' />
                </Form.Item>

                <Form.Item className={ styles.input } rules={ rules.userName } name='userName' >
                    <Input type="text" size='large' placeholder="Username" />
                </Form.Item>

                <Form.Item className={ styles.input } rules={ rules.password } name='password'>
                    <Input.Password placeholder="Password" size='large' visibilityToggle />
                </Form.Item>

                <Form.Item className={ styles.input } rules={ [...rules.password, () => ({
                    validator(_, value) {
                        if (!value || password === value) {
                            return Promise.resolve();
                        }

                        return Promise.reject(new Error('The passwords do not match!'));
                    },
                })] } name='confirmPassword' dependencies={ ['password'] } >
                    <Input.Password placeholder="Confirm Password" size='large' visibilityToggle />
                </Form.Item>

                <Form.Item name="accept" valuePropName='checked'>
                    <Checkbox>
                        I have read and accepted the privacy policy and Terms and Conditions
                        <a href="/privacy-policy" target="_blank" style={ { display: "block", textDecoration: "underline" } }>Show Privacy Policy <Icon component={ ExternalLink } /></a>
                        <a href="/terms-and-conditions" target="_blank" style={ { display: "block", textDecoration: "underline" } }>Show Terms and Conditions <Icon component={ ExternalLink } /></a>
                    </Checkbox>
                </Form.Item>

                <Button type="primary" size='large' block htmlType='submit' disabled={ !accept }>SIGN UP</Button>
            </Form>

            <FullScreenSpinner loading={ registeringState.registerRequest } />
        </div>
    );
}

export default SignUp;