import { serviceWorkerConstants } from "../_constants/serviceWorker.constants";


export function serviceWorker(state = {}, action) {
    switch (action.type) {

        case serviceWorkerConstants.SW_SET_STATE:
            return {
                swState: action.state
            }

        default:
            return state
    }
}