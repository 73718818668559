import { userConstants } from '../_constants/user.constants';

export function registration(state = {}, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return { 
                registerRequest: true 
            };
        case userConstants.REGISTER_SUCCESS:
            return {
                registerSuccess: action.user.success,
                message: action.user.message
            };
        case userConstants.REGISTER_FAILURE:
            return {
                registerFailure: action.error,
                type: "error"
            };
        case userConstants.CLEAR_REGISTER:
            return {};
        default:
            return state
    }
}