import { Alert, Button, Space } from "antd";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configurationActions } from '../../../../../api/_actions/configuration.actions';
import styles from "./blinker.module.css";
import { useStateCallback } from "../../../../../hooks/useStateCallback";
import { mp4 } from '../../../../../assets/videos/videos';

function Blinker({ device }) {
    const dispatch = useDispatch();
    const configurationState = useSelector(state => state.configuration);

    const [configuration, setConfiguration] = useState([]);
    const [currentConfiguration, setCurrentConfiguration] = useState([]);
    const [showVideo, setShowVideo] = useState(true);

    const [ready, setReady] = useState(true);
    const [countdown, setCountdown] = useState(0);
    const [countdownStarted, setCountdownStarted] = useState(false);
    const [webgl, setWebgl] = useStateCallback(null);

    const canvasRef = useRef(null);
    const containerRef = useRef(null);

    const blinkRef = useRef(null);
    const countdownRef = useRef(null);

    const CONFIGURATION = 15,
        DELETE_ALL_CODES = 7,
        ADD_ALL_CODES = 5;


    useEffect(() => {
        if (configuration.length === 1 || getConfigIndex() === 0) {
            startCountdown();
        }
    }, [currentConfiguration]) // eslint-disable-line

    const getConfigIndex = () => configuration.findIndex(c => c === currentConfiguration)

    useEffect(() => {
        setTimeout(() => {
            setWebgl(canvasRef.current.getContext('webgl'), (_webgl) => {
                _webgl.clearColor(0.0, 0.0, 0.0, 1.0); // Set clear color to black, fully opaque
                _webgl.clear(_webgl.COLOR_BUFFER_BIT); // Clear the color buffer with specified clear color
            });
        }, 1);

        const container = containerRef.current;

        const observer = new ResizeObserver(() => {
            canvasRef.current.width = container.offsetWidth;
            canvasRef.current.height = container.offsetHeight;
        });

        observer.observe(container);

        document.addEventListener("visibilitychange", visibilityChange)

        return () => {
            observer.unobserve(container);
            clearTimeout(countdownRef.current);
            window.cancelAnimationFrame(blinkRef.current);
            document.removeEventListener("visibilitychange", visibilityChange)
        }
    }, []); // eslint-disable-line

    function selectConfig() {
        setConfiguration([]);
    }

    function startCurrentConfiguration() {
        startCountdown();
    }

    function startNextConfiguration() {
        let nextIndex = getConfigIndex() + 1;

        setCurrentConfiguration(configuration[nextIndex]);
        startCountdown();
    }

    function startCountdown() {
        setReady(false);
        setCountdownStarted(true);
        setCountdown(5);
    }

    useEffect(() => {
        if (countdown === 0 && countdownStarted) {
            setCountdownStarted(false);
            start();
        }
        else if (countdown > 0) {
            countdownRef.current = setTimeout(() => {
                setCountdown(prev => prev - 1);
            }, 1000);
        }
    }, [countdown]) // eslint-disable-line

    function start() {
        const preAmple = [1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 1, 2, 2, 1, 2, 0, 2];
        const bitArray = currentConfiguration.join("").split("").map(Number);
        let i = 0;
        let toggleBit = 1;

        blinkPreAmple();

        function blinkPreAmple() {
            draw(preAmple[i]);

            if (i++ < preAmple.length) {
                blinkRef.current = window.requestAnimationFrame(blinkPreAmple);
            }
            else {
                i = 0;
                blinkRef.current = window.requestAnimationFrame(blinkConfiguration);
            }
        }

        function blinkConfiguration() {
            toggleBit ^= 1;
            if (toggleBit) {
                draw(2);
                blinkRef.current = window.requestAnimationFrame(blinkConfiguration);
            }
            else {
                draw(bitArray[i]);

                i++ < bitArray.length
                    ? blinkRef.current = window.requestAnimationFrame(blinkConfiguration)
                    : finish();
            }
        }
    }

    function finish() {
        setReady(true);

        configuration.length === 1 && selectConfig();
    }

    function draw(val) {
        switch (val) {
            case 1: webgl.clearColor(1, 1, 1, 1); break;       // White
            case 0: webgl.clearColor(.75, .75, .75, 1); break; // Grey
            default: webgl.clearColor(0, 0, 0, 1); break;      // Black
        }
        webgl.clear(webgl.COLOR_BUFFER_BIT);
    }

    function startConfig(configType) {
        dispatch(configurationActions.getConfiguration(device.keypadId, configType)).then(result => {
            if (result?.data) {
                setConfiguration(result.data);
                setCurrentConfiguration(result.data[0]);
                dispatch(configurationActions.clear());
            } else {
                selectConfig();
            }
        })
    }

    function visibilityChange() {
        if ("hidden" in document) {
            setShowVideo(!document.hidden)
        }
    }

    return (
        <div className={ styles.wrapper }>
            { configurationState.configurationError && <Alert type="error" message={ configurationState.configurationError.message } /> }

            <div className={ styles.canvasContainer } ref={ containerRef }>
                <canvas className={ styles.canvas } ref={ canvasRef }>
                    Your browser does not support the HTML5 canvas tag.
                </canvas>

                <div className={ styles.optionsContainer }>
                    { ready ? <Instructions /> : <div style={ { padding: 0 } }></div> }

                    { configuration.length === 0 ?
                        <Space direction='vertical' className={ styles.blinkOptions }>
                            <h3>Blink options</h3>
                            <Button size='large' type='primary' block disabled={ configurationState.gettingConfiguration }
                                loading={ configurationState.configType === CONFIGURATION }
                                onClick={ () => startConfig(CONFIGURATION) }>
                                Configuration
                            </Button>
                            <Button size='large' type='primary' block disabled={ configurationState.gettingConfiguration }
                                loading={ configurationState.configType === ADD_ALL_CODES }
                                onClick={ () => startConfig(ADD_ALL_CODES) }>
                                Add all codes
                                { device?.keypadUserCodes.length > 10 && `, ${Math.ceil(device?.keypadUserCodes.length / 10)} steps` }
                            </Button>
                            <Button size='large' type='primary' block disabled={ configurationState.gettingConfiguration }
                                loading={ configurationState.configType === DELETE_ALL_CODES }
                                onClick={ () => startConfig(DELETE_ALL_CODES) }>
                                Delete all codes
                            </Button>
                        </Space>

                        : configuration.length > 1 &&
                        <div className={ styles.selectConfigButtons }>
                            <Button size='large' disabled={ !ready } onClick={ startCurrentConfiguration }>Blink again</Button>

                            { configuration.length === getConfigIndex() + 1
                                ? <Button size='large' type='primary' block disabled={ !ready } onClick={ selectConfig }>Done</Button>
                                : <Button size='large' type='primary' block disabled={ !ready } onClick={ startNextConfiguration }>
                                    Start{ ` ${getConfigIndex() + 2} of ${configuration.length}` }
                                </Button>
                            }
                        </div>
                    }
                </div>
            </div>

            { countdownStarted &&
                <div className={ styles.countdown }>
                    <p>{ countdown }</p>
                </div>
            }

            { showVideo && !ready &&
                <Video />
            }
        </div>
    );
}

const Video = () =>
    <video height="100" width="100" playsInline controls autoPlay loop className={ styles.video } title="Code locker">
        <source type="video/mp4" src={ mp4 } />
    </video>

const Instructions = () =>
    <div className={ styles.instructions }>
        <p>Insert:</p>
        <p>{ "<Service Configuration Code>#7##" }</p>
        <p>In the XS4 Code Locker before you choose a blink option</p>
    </div>

export default Blinker;