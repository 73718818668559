import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { configuration } from './configuration.reducer';
import { users } from './users.reducer';
import { codes } from "./codes.reducer";
import { keypads } from './keypad.reducer';
import { callFunction } from "./callFunction.reducer";
import { alert } from './alert.reducer';
import { serviceWorker } from './serviceWorker.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    configuration,
    users,
    codes,
    keypads,
    serviceWorker,
    callFunction,
    alert
});

export default rootReducer;