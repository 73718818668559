import { userConstants } from '../_constants/user.constants';
import { userService } from '../_services/user.service';
import { alertActions } from './alert.actions';
import { errorHandler } from '../_helpers/errorHandler';
import { getToken } from '../../helpers/sessionHelper';
import { navigate } from '../../helpers/navigationHelper';

export const userActions = {
    login,
    getUserInfo,
    logout,
    register,
    changePassword,
    updateInfo,
    getAll,
    forgotPassword,
    updateTourState,
    checkEmail,
    delete: _delete,
    clearUsers,
    clearAll
};

function login(email, password) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request({ email }));
            userService.login(email, password)
                .then(
                    result => {
                        dispatch(success(result.userInfo));
                        dispatch(alertActions.success(result.message))
                        navigate("/lockers")
                        resolve(result);
                    },
                    error => {
                        dispatch(failure({ message: error.message, success: false }));
                        // errorHandler(
                        //     dispatch,
                        //     error,
                        //     failure,
                        //     alertActions.error
                        // )
                        reject(error);
                    }
                );
        }).catch(e => { });
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getUserInfo() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());
            userService.getUserInfo()
                .then(
                    result => {
                        dispatch(success(result.userInfo));
                        resolve(result);
                    },
                    error => {
                        let token = getToken()
                        if (token) { // if token exists, it was not an authentication error
                            dispatch(alertActions.error(error.message))
                        }
                        dispatch(failure({ message: error.message, success: false }));
                        userService.logout();
                        reject(error);
                    }
                );
        }).catch(e => { });
    };

    function request() { return { type: userConstants.GET_USER_INFO_REQUEST } }
    function success(user) { return { type: userConstants.GET_USER_INFO_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_USER_INFO_FAILURE, error } }
}

function logout() {
    return dispatch => {
        dispatch(alertActions.success("You have logged out"))
        userService.logout();
        return { type: userConstants.LOGOUT };
    }
}

function register(user) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(user));

            userService.register(user)
                .then(
                    response => {
                        if (response.success) {
                            dispatch(success(response));
                            dispatch(alertActions.success(response.message));
                        } else {
                            dispatch(failure(response))
                        }
                        resolve(response);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject();
                    }
                );
        }).catch(e => { });
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function changePassword(password) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(password));

            userService.changePassword(password)
                .then(
                    result => {
                        dispatch(success(result));
                        // dispatch(alertActions.success(result.message));
                        resolve()
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject();
                    }
                );
        }).catch(e => { });
    };



    function request(user) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, user } }
    function success(result) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, result } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function updateInfo({ prop, value }) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());

            userService.updateInfo(prop, value)
                .then(
                    result => {
                        if (result.success) {
                            dispatch(updateAuth(result.userInfo))
                            delete result.userInfo;
                        }
                        dispatch(success({ prop, ...result }));
                        // dispatch(alertActions.success(result.message));
                        resolve();
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject();
                    }
                );
        }).catch(e => { });
    };



    function request(user) { return { type: userConstants.UPDATE_INFO_REQUEST, user } }
    function success(result) { return { type: userConstants.UPDATE_INFO_SUCCESS, result } }
    function failure(error) { return { type: userConstants.UPDATE_INFO_FAILURE, error } }

    function updateAuth(userInfo) { return { type: userConstants.UPDATE_AUTH, userInfo } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                result => {
                    dispatch(success(result))
                    dispatch(alertActions.success(result.message));
                },
                error => {
                    errorHandler(
                        dispatch,
                        error,
                        failure,
                        alertActions.error
                    )
                }
            )

    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function forgotPassword(email) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(email));

            userService.forgotPassword(email)
                .then(
                    response => {
                        dispatch(success(response));
                        // dispatch(alertActions.success(response.message));
                        resolve(response);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject(error);
                    }
                );
        }).catch(e => { });
    };

    function request(email) { return { type: userConstants.FORGOT_PASSWORD_REQUEST, email } }
    function success(result) { return { type: userConstants.FORGOT_PASSWORD_SUCCESS, result } }
    function failure(error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, error } }
}

function updateTourState(tourName, state) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(tourName, state));

            userService.updateTourState(tourName, state)
                .then(
                    result => {
                        dispatch(success(result));

                        if (result.success) {
                            dispatch(updateAuth(result.userInfo))
                            delete result.userInfo;
                        }

                        // dispatch(alertActions.success(response.message));
                        resolve();
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject();
                    }
                );
        }).catch(e => { });
    };

    function request(email) { return { type: userConstants.UPDATE_TOUR_STATE_REQUEST, email } }
    function success(result) { return { type: userConstants.UPDATE_TOUR_STATE_SUCCESS, result } }
    function failure(error) { return { type: userConstants.UPDATE_TOUR_STATE_FAILURE, error } }

    function updateAuth(userInfo) { return { type: userConstants.UPDATE_AUTH, userInfo } }
}

function checkEmail(email) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(email));

            userService.checkEmail(email)
                .then(
                    result => {
                        dispatch(success(result));
                        resolve(result);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject(error);
                    }
                );
        }).catch(e => { });
    };

    function request(email) { return { type: userConstants.CHECK_EMAIL_REQUEST, email } }
    function success(result) { return { type: userConstants.CHECK_EMAIL_SUCCESS, result } }
    function failure(error) { return { type: userConstants.CHECK_EMAIL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}
// function clear() { return { type: userConstants.CLEAR_USER } }

function clearUsers() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(clear());
            resolve()
        }).catch(e => { });
    }

    function clear() { return { type: userConstants.CLEAR_USER } }
}

function clearAll() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(clearUser());
            dispatch(clearAuth());
            dispatch(clearRegister());
            resolve()
        }).catch(e => { });
    }

    function clearUser() { return { type: userConstants.CLEAR_USER } }
    function clearAuth() { return { type: userConstants.CLEAR_AUTHENTICATION } }
    function clearRegister() { return { type: userConstants.CLEAR_REGISTER } }
}