import { getUser } from "../../helpers/sessionHelper";


function getOptions() {
    let user = getUser();

    let isDeveloper = user.developer;

    let options = [
        {
            value: 1,
            label: "Free locker"
        },
        {
            value: 0,
            label: "Timed Pin Code"
        },
        {
            value: 2,
            label: "Mail Box",
            disabled: !isDeveloper
        }
    ]
    return options;
}


export default getOptions;