import { LeftOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Carousel, Drawer, Result, Spin, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { codeActions } from '../../api/_actions/code.actions';
import { keypadActions } from '../../api/_actions/keypad.actions';
import FullScreenSpinner from '../../components/FullscreenSpinner/fullscreenSpinner';
import Locker from './Locker/locker';
import TimedPinCodes from './Locker/TimedPinCodes/timedPinCodes';
import styles from "./lockers.module.css";
import { cloneDeep } from "lodash";
import ActivationCodesDrawer from '../../components/ActivationCodesDrawer/activationCodesDrawer';
import JoyRide, { ACTIONS } from "react-joyride";
import startupGuide from "../../helpers/tourSteps/startupGuide";
import { userActions } from '../../api/_actions/user.actions';
import { navigate, toggleConfig } from '../../helpers/navigationHelper';
import Blinker from './Locker/LockerConfiguration/Blinker/blinker';
import LockerConfiguration from './Locker/LockerConfiguration/lockerConfiguration';

function Lockers() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.keypads.getAllRequest);
    const keypadState = useSelector(state => state.keypads);
    const codeState = useSelector(state => state.codes);
    const user = useSelector(state => state.authentication.user);

    const { id, config } = useParams();

    const [devices, setDevices] = useState();
    const [swipeIndex, setSwipeIndex] = useState(0);

    const location = useLocation()

    const {
        blinkIsOpen,
        lockerConfigIsOpen,
        pinGenerationIsOpen
    } = location.state || {}
    
    useEffect(() => {
        if (!devices) {
            getDevices();
        }
    }, []) //eslint-disable-line    

    useEffect(() => {
        if (keypadState.devices || keypadState.devices === []) {
            setDevices(keypadState.devices);
            dispatch(keypadActions.clear());
        }
    }, [keypadState.devices]) //eslint-disable-line

    useEffect(() => {
        if (swipeIndex >= devices?.length - 1) {
            setSwipeIndex(devices?.length - 1);
        }
        if (devices?.length > 0 && id) {

            let swipeIndex = devices.findIndex(d => d.keypadId === id);

            if (swipeIndex !== -1) {
                carouselRef.current.goTo(swipeIndex, true);
                setSwipeIndex(swipeIndex);
            } else {
                navigate("/lockers");
            }
        }
    }, [devices]) //eslint-disable-line

    useEffect(() => {
        if (codeState.updateUsercodesSuccess) {
            getDevices();
        }
    }, [codeState.updateUsercodesSuccess]) //eslint-disable-line

    function getDevices() {
        dispatch(keypadActions.getAll()).then((_devices) => {
            if (_devices && config) {
                toggleConfig(true)
            }
        });
    }

    function setDevice(device) {
        setDevices(prev => {
            let swipeIndex = prev.findIndex(x => x.id = device.id);
            prev[swipeIndex] = device
            return prev;
        })
    }

    function startupGuideCallback(params) {
        const { action } = params;

        if (action === ACTIONS.RESET) {
            dispatch(userActions.updateTourState("startupGuide", true));
        }

    }

    const carouselRef = useRef(null);
    return (
        <div className={ styles.wrapper }>
            <JoyRide continuous showProgress showSkipButton hideCloseButton
                steps={ startupGuide }
                run={ (user && !user.tours?.startupGuideCompleted) || false }
                callback={ startupGuideCallback }
            />
            <Typography.Title level={ 1 } className={ styles.header }>Lockers</Typography.Title>
            <div className={ styles.devicesContainer }>
                { loading && !devices ?
                    <div className={ styles.loadingContainer }>
                        <div className={ styles.loading }>
                            <Spin size='large' className={ styles.spinner } />
                            <h3>Loading devices...</h3>

                        </div>
                    </div> : devices?.length > 0 ?
                        <Carousel ref={ carouselRef } swipeToSlide draggable dots={ false } infinite={ false } speed={ 300 }
                            afterChange={ (to) => {
                                let id = devices[to]?.keypadId
                                navigate(`/lockers/${id}`,{replace: true, state: location.state});

                                setSwipeIndex(to)
                            } }>
                            { devices.sort((a, b) => a.keypadRegisterDate || 0 - b.keypadRegisterDate || 0).map((device, i) =>
                                <Locker device={ device } key={ device.keypadId } isActiveDevice={ swipeIndex === i } />
                            ) }
                        </Carousel>
                        : keypadState.error ?
                            <Result
                                status="warning"
                                title={ keypadState.error?.message }
                            /> :
                            <div className={ styles.noLockersFound }>
                                <h1>No Lockers Found</h1>
                                <h2>Add Locker</h2>
                                <Link to="/register-locker"><PlusCircleFilled className={ styles.addDevice } /></Link>
                            </div>

                }
            </div>

            <FullScreenSpinner loading={ codeState.deleteOldOneTimeCodesRequest || (loading && devices) } />

            {
                devices?.length > 0 && devices[swipeIndex] &&
                <>

                    <Drawer
                        destroyOnClose={ true }
                        getContainer="#root .content"
                        push={ false }
                        placement="bottom"
                        open={ lockerConfigIsOpen }
                        closable={ false }
                        contentWrapperStyle={ { height: "100%", padding: "0px" } }
                        bodyStyle={ { padding: "0px" } }>

                        <LockerConfiguration device={ cloneDeep(devices[swipeIndex]) } />
                    </Drawer>

                    <ActivationCodesDrawer
                        device={ cloneDeep(devices[swipeIndex]) }
                        setDevice={ setDevice }
                    />

                    <Drawer
                        destroyOnClose={ true }
                        getContainer="#root .content"
                        className='drawer-header-center'
                        title="Configure Device"
                        headerStyle={ { textAlign: "center" } }
                        closeIcon={ <LeftOutlined /> }
                        onClose={ ()=> {
                            navigate(-1)
                            dispatch(keypadActions.clear());
                        } }
                        open={ blinkIsOpen }
                        closable={ true }
                        contentWrapperStyle={ { width: "100%", padding: "0px" } }
                        bodyStyle={ { padding: "0px" } }>

                        <Blinker device={ devices[swipeIndex] } />
                    </Drawer>

                    <Drawer
                        destroyOnClose={ true }
                        getContainer="#root"
                        className={ ['drawer-close-icon-right', 'timed-pin-codes-drawer'].join(" ") }
                        headerStyle={ { textAlign: "center", padding: "16px .8rem", border: "none" } }
                        onClose={ () => {
                            navigate(-1)
                            dispatch(codeActions.clear());
                        } }
                        open={ pinGenerationIsOpen }
                        closable={ true }
                        contentWrapperStyle={ { width: "100%", padding: "0px" } }
                        bodyStyle={ { padding: "0px" } }>

                        <TimedPinCodes device={ devices[swipeIndex] } />
                    </Drawer>
                </>
            }
        </div >
    );
}

export default Lockers;