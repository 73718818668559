import {timezones as ianaTimezones} from "../assets/data/iana_timezones";

export class TimezoneHelper {

    static getTimezoneName() {
        let timezone;

        try {
            timezone = Intl.DateTimeFormat([]).resolvedOptions().timeZone
        } catch (error) {
            timezone = null
        }

        return timezone
    }

    static getClientOffset() {
        return new Date().getTimezoneOffset() * -60;
    }

    static getAllTimezones() {
        let timezones = []
        try {
            timezones = Intl.supportedValuesOf('timeZone');
            
        } catch (error) {
            timezones = ianaTimezones
        }
        return timezones;
    }

    static getOffsetFromTimezoneName(timezoneName) {

        let offset;
        try {
            let dateformat = new Intl.DateTimeFormat([], { timeZone: timezoneName, timeZoneName: 'shortOffset' })

            let timezonePart = dateformat.formatToParts().find(o => o.type === 'timeZoneName')

            if (timezonePart)
                offset = parseInt(timezonePart?.value.substring(4)) * 60 * 60
            else
                throw Error();

        } catch (error) {
            throw Error("Error getting timezone offset")
        }
        return offset;
    }


}