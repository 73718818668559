import { configurationConstants } from '../_constants/configuration.constants';

export function configuration(state = {}, action) {
    switch (action.type) {

        // #region GET_CONFIGURATION
        case configurationConstants.GET_CONFIGURATION_REQUEST:
            return {
                gettingConfiguration: true,
                configType: action.configType
            }
        case configurationConstants.GET_CONFIGURATION_SUCCESS:
            return {
                data: action.result
            }
        case configurationConstants.GET_CONFIGURATION_FAILURE:
            return {
                configurationError: action.error
            }
        // #endregion

        case configurationConstants.CLEAR_CONFIGURATION:
            return {};

        default:
            return state
    }
}