import { Link } from 'react-router-dom';
import { userActions } from "../../api/_actions/user.actions";
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import styles from "./login.module.css";
import rules from "../../helpers/validation/userValidation";
import FullScreenSpinner from '../../components/FullscreenSpinner/fullscreenSpinner';
import { useStateCallback } from "../../hooks/useStateCallback";
import { saveRememberEmail, getRememberEmail } from "../../helpers/sessionHelper";

function Login() {
    const dispatch = useDispatch();

    const authenticationState = useSelector(state => state.authentication);
    const userState = useSelector(state => state.users);
    const [message, setMessage] = useStateCallback({ success: null, message: null })

    const [form] = Form.useForm();

    useEffect(() => {
        let error = authenticationState.loginFailure?.error || userState.forgotPasswordFailure;

        if (error) {
            setMessage({
                ...error
            }, () => {
                dispatch(userActions.clearAll())
            })
        }
    }, [authenticationState.loginFailure || userState.forgotPasswordFailure]) // eslint-disable-line

    function submit({ email, password, rememberEmail }) {
        if (rememberEmail)
            saveRememberEmail(email);

        dispatch(userActions.login(email, password));
    }

    return (
        <div className={ styles.wrapper }>
            <Link to="/" className={ styles.back }><ArrowLeftOutlined /></Link>
            <h1 className={ styles.header }>Log in</h1>

            { message.message &&
                <Alert type={ message.success ? "success" : "error" } message={ message.message } />
            }

            <Form className={ styles.form } form={ form } onFinish={ submit } validateTrigger="onSubmit" initialValues={ { email: getRememberEmail(), rememberEmail: true } }>
                <Form.Item rules={ rules.email } name="email">
                    <Input placeholder="Email" size='large' inputMode='email' />
                </Form.Item>

                <Form.Item rules={ rules.password } name='password'>
                    <Input.Password placeholder="Password" size='large' visibilityToggle />
                </Form.Item>

                <Form.Item name='rememberEmail' valuePropName='checked'>
                    <Checkbox size='large' >
                        Remember email
                    </Checkbox>
                </Form.Item>

                <Button type="primary" size='large' name='login' htmlType='submit'>LOG IN</Button>
            </Form>
            <Link to="/forgot-password" className={ styles.forgotPassword }>Forgot password?</Link>
            <FullScreenSpinner loading={ authenticationState.loggingIn } />
        </div>
    );
}

export default Login;