import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

// const loggerMiddleware = createLogger();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let inDevelopment = process.env.NODE_ENV === "development";

const store =
    inDevelopment ?
        createStore(
            rootReducer,
            composeEnhancer(applyMiddleware(
                thunkMiddleware,
                // loggerMiddleware
            ))
        ) :
        createStore(
            rootReducer,
            applyMiddleware(
                thunkMiddleware,
                // loggerMiddleware
            )
        )

export default store;