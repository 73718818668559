import { Alert, Button, Collapse } from "antd";
import { useSelector } from "react-redux";
import styles from "./serviceWorkerAlert.module.css";

const ServiceWorkerAlert = () => {
    const swState = useSelector(state => state.serviceWorker.swState)

    async function restart() {
        let registration = await navigator.serviceWorker?.getRegistration()

        if (registration) {
            registration.waiting.addEventListener("statechange", e => {
                if (e.target.state === "activated") {
                    window.location.reload()
                }
            })
            registration.waiting.postMessage({ type: "SKIP_WAITING" });   
        }
    }

    return (
        <Collapse activeKey={ swState === "installed" ? "1" : "0" } ghost items={ [{
            key: "1",
            className: "collapseHideHeader",
            children: <>
                <Alert className={ styles.banner } type="success" banner message="A new version is installed" action={
                    <Button type="primary" onClick={ restart }>
                        Restart
                    </Button> }
                />
            </>
        }] } />
    )
}

export default ServiceWorkerAlert;