import { callFunctionConstants } from '../_constants/callFunction.constants';

export const callFunctionActions = {
    call,
    clear
};

function call(message) {
    return { type: callFunctionConstants.CALL, message };
}

function clear() {
    return { type: callFunctionConstants.CLEAR_FUNCTION };
}