import { Link } from 'react-router-dom';
import styles from "./devices.module.css";
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Button, Popconfirm, Col, Drawer, Input, List, Modal, Progress, Result, Row, Typography } from 'antd';
import { DeleteOutlined, FilterOutlined, LeftOutlined, MinusCircleFilled, PlusCircleFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { keypadActions } from '../../api/_actions/keypad.actions';
import lockerImg from "../../assets/images/CodeLocker_Graphic_old.png";
import { LongPressDetectEvents, useLongPress } from "use-long-press";
import { useStateCallback } from '../../hooks/useStateCallback';
import useSortDrawer from '../../hooks/useSortDrawer';
import cloneDeep from "lodash/cloneDeep";
import { navigate } from '../../helpers/navigationHelper';

function Devices() {
    const dispatch = useDispatch();

    let loading = useSelector(state => state.keypads.getAllRequest);
    let keypadState = useSelector(state => state.keypads);

    const searchInputRef = useRef();

    const [devices, setDevices] = useState([]);
    const [configuring, setConfiguring] = useState(false)
    const [searching, setSearching] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [foundDevices, setFoundDevices] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchFocus, setSearchFocus] = useState(false);

    const sortOptions = [
        {
            name: "Name",
            prop: "keypadCustomName",
            order: "asc"
        },
        {
            name: "Registration date",
            prop: "keypadRegisterDate",
            order: "des"
        }
    ]
    const [sortBy, setSortBy] = useState(sortOptions[1]);
    const [sortDrawer, openSortDrawer] = useSortDrawer(sortOptions, setSortBy)

    useEffect(() => {
        getDevices();
    }, []); //eslint-disable-line

    useEffect(() => {
        if (keypadState.devices) {
            setDevices(keypadState.devices);
            dispatch(keypadActions.clear());
        }
    }, [keypadState.devices]) //eslint-disable-line

    // Set focus when start searhing
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searching])

    function getDevices() {
        dispatch(keypadActions.getAll());
        setDevices(null);
    }

    useEffect(() => {
        sortDevices();
    }, [searchString, devices, sortBy]) // eslint-disable-line


    function sortDevices() {
        if (devices) {
            let tempDevices = [...devices] || [];

            if (searchString) {
                const searchKeywords = searchString.split(" ");
                tempDevices = tempDevices.filter(device =>
                    searchKeywords.every(word =>
                        device.keypadCustomName.toLowerCase()
                            .includes(word.toLowerCase())
                    )
                );
            }

            const { prop, order } = sortBy;

            if (tempDevices.length > 1) {
                tempDevices = tempDevices.sort((a, b) => {
                    a[prop] = a[prop] ? "" + a[prop] : "0";
                    b[prop] = b[prop] ? "" + b[prop] : "0";

                    if (order === "asc")
                        return a[prop].localeCompare(b[prop]);
                    else
                        return b[prop].localeCompare(a[prop]);

                })
            }
            setFoundDevices(tempDevices)
        }
    }

    function deviceList() {
        return <div className={ styles.searchResults }>
            { keypadState.error
                ? <Result status="warning" title={ keypadState.error.message } />
                : devices?.length === 0 && !keypadState.getAllRequest
                    ? <div className={ styles.noLockersFound }>
                        <h1>No Lockers Found</h1>
                        <h2>Add Locker</h2>
                        <Link to="/register-locker"><PlusCircleFilled className={ styles.addDevice } /></Link>
                    </div>
                    : <List
                        locale={ { emptyText: true } }
                        loading={ loading }
                        split={ false }
                        size='default'
                        itemLayout="horizontal"
                        dataSource={ foundDevices }
                        renderItem={ (device, i) =>
                            <ListItem key={ i } device={ device } configuring={ configuring } setConfiguring={ setConfiguring } />
                        }
                    />
            }
        </div>
    }

    return (
        <div className={ styles.wrapper }>
            <Drawer
                push={ false }
                bodyStyle={ { padding: 0 } }
                closeIcon={ <LeftOutlined /> }
                contentWrapperStyle={ { width: "100%", padding: "0px" } }
                getContainer="#root .content"
                open
                motion={ null }
                keyboard={ false }
                onClose={ () => navigate("/lockers") }
                className={ styles.drawer }
                title={ searching
                    ? <Input bordered={ false } placeholder='Search' defaultValue={ searchString } ref={ searchInputRef }
                        onFocus={ () => setSearchFocus(true) }
                        onBlur={ () => setSearchFocus(false) }
                        onChange={ (e) => setSearchString(e.target.value) } />
                    : <>Devices</> }
                extra={
                    <div className={ styles.options } style={ { width: searchFocus ? "34px" : "102px" } }>

                        <SearchOutlined onClick={ () => { setSearching(prev => !prev) } } />
                        <FilterOutlined onClick={ openSortDrawer } />

                        { configuring ?
                            <>
                                <DeleteOutlined style={ { color: foundDevices?.length === 0 && "#bbb" } }
                                    onClick={ () => foundDevices?.length > 0 && setShowDeleteModal(true) } />
                                <DeleteModal showDeleteModal={ showDeleteModal } setShowDeleteModal={ setShowDeleteModal } getDevices={ getDevices } devices={ foundDevices } />
                            </> :
                            <PlusOutlined onClick={ () => navigate("/register-locker") } />
                        }
                    </div>
                }
            >
                { deviceList() }
                { sortDrawer }
            </Drawer>
        </div>
    );
}

function DeleteModal({ showDeleteModal, setShowDeleteModal, devices = [] }) {
    const dispatch = useDispatch();

    const [startedDeleting, setStartedDeleting] = useState(false);
    const [doneDeleting, setDoneDeleting] = useState(false);
    const [devicesToDelete, setDevicesToDelete] = useStateCallback(cloneDeep(devices));

    const [devicesToDeleteCount, setDevicesToDeleteCount] = useState(cloneDeep(devices).length);

    useEffect(() => {
        setDevicesToDelete(cloneDeep(devices));
        setDevicesToDeleteCount(cloneDeep(devices).length);
        setDoneDeleting(false);
        setStartedDeleting(false);
    }, [showDeleteModal]) // eslint-disable-line



    function deleteDevices() {
        setStartedDeleting(true);

        devicesToDelete.forEach((device, index) => {
            dispatch(keypadActions.deleteKeypad(device)).then(result => {
                setDevicesToDelete(prev => {
                    prev = prev.filter(d => d.keypadId !== device.keypadId)
                    if (prev.length === 0) {
                        setDoneDeleting(true);
                    }
                    return prev;
                }, (e) => {
                    if (e.length === 0) {
                        dispatch(keypadActions.getAll());
                    }
                })
            })
        });
    }

    function closeModal() {
        setShowDeleteModal(false)
    }

    return (
        <Modal centered open={ showDeleteModal } title="Delete all Lockers?" footer={ false } destroyOnClose
            bodyStyle={ { display: "flex", flexDirection: "column", overflowX: "hidden", overflowY: "auto", maxHeight: "calc(100vh - 140px)" } }
            onCancel={ () => {
                if (!startedDeleting || doneDeleting) closeModal();
            } }
        >

            { !startedDeleting &&
                <p>Are you sure you want to delete these devices?</p>
            }
            { doneDeleting &&
                <p>Devices deleted</p>
            }

            { devicesToDelete?.length > 0 &&
                <List disabled style={ { overflow: "auto", marginBottom: "20px", border: "1px solid #ddd", flexShrink: "1" } } itemLayout='horizontal'
                    dataSource={ devicesToDelete }
                    renderItem={ device =>
                        <List.Item>
                            <p style={ { margin: "0 5px" } }>{ device.keypadCustomName }</p>
                        </List.Item> }>
                </List>
            }


            { startedDeleting &&
                <Progress style={ { marginBottom: "20px" } } status={ devicesToDelete.length > 0 ? 'active' : 'success' }
                    percent={ (100 * devicesToDeleteCount - devicesToDelete.length) / devices.length }
                    format={ () => `${devicesToDeleteCount - devicesToDelete.length} / ${devicesToDeleteCount}` } />
            }
            { doneDeleting ?
                <Button onClick={ closeModal } size="large">DONE</Button> :
                <Row gutter={ [8, 0] } >
                    <Col span={ 12 } >
                        <Button block size="large" disabled={ startedDeleting } onClick={ closeModal }>CANCEL</Button>
                    </Col>
                    <Col span={ 12 } >
                        <Button block size="large" type='primary' danger onClick={ () => deleteDevices() } loading={ startedDeleting }>{ startedDeleting ? "DELETING" : "DELETE" }</Button>
                    </Col>
                </Row>
            }
        </Modal>
    )
}

function ListItem({ device, setConfiguring, configuring }) {
    const { Paragraph } = Typography;
    const [isClick, setIsClick] = useState(true);

    const dispatch = useDispatch();

    function navigateToLockers(openConfig = false) {
        navigate(`/lockers/${device.keypadId}${openConfig ? "/config" : ""}`);
    };

    function deleteDevice() {
        dispatch(keypadActions.deleteKeypad(device)).then(() => {
            dispatch(keypadActions.getAll());
        });
    }

    const callback = useCallback(event => {
        setIsClick(false);
        setConfiguring(true);
    }, [setConfiguring]);

    const bind = useLongPress(callback, {
        onStart: () => setIsClick(true),
        onFinish: () => setIsClick(false),
        onMove: () => setIsClick(false),
        filterEvents: (event) => 'button' in event ? event.button === 0 : true,
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: 5,
        detect: LongPressDetectEvents.BOTH
    });

    return (
        <List.Item className={ styles.listItem }
            onClick={ () => { isClick ? configuring ? setConfiguring(false) : navigateToLockers() : setIsClick(false) } }
            { ...bind() }
            actions={ [
                <Popconfirm title="Are you sure you want to delete this device?"
                    destroyTooltipOnHide placement='bottom' arrow={ { pointAtCenter: true } } okText="Yes" cancelText="No"
                    okButtonProps={ { size: "middle", danger: true } }
                    cancelButtonProps={ { size: "middle" } }
                    onCancel={ e => e.stopPropagation() }
                    onConfirm={ e => {
                        e.stopPropagation();
                        deleteDevice()
                    } }
                >
                    <div onClick={ e => e.stopPropagation() } className={ [styles.deleteButton, configuring ? styles.show : ""].join(" ") } ><MinusCircleFilled /></div>
                </Popconfirm>
            ] }
        >
            <List.Item.Meta
                avatar={ <img style={ { height: "60px" } } src={ lockerImg } alt="locker"></img> }
                title={ <Paragraph ellipsis={ true } >{ device.keypadCustomName }</Paragraph> }
                description={ <Paragraph ellipsis={ true }>{ device.keypadComment }</Paragraph> }
            />
        </List.Item>
    )
}

export default Devices;