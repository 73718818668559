import { userConstants } from '../_constants/user.constants';

export function users(state = {}, action) {
    switch (action.type) {

        //#region GETALL
        case userConstants.GETALL_REQUEST:
            return {
                getAllRequest: true
            };
        case userConstants.GETALL_SUCCESS:
            return {
                items: action.users
            };
        case userConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        //#endregion

        //#region CHANGE_PASSWORD
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return {
                changePasswordRequest: true
            }
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                changePasswordSuccess: action.result
            }
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {
                error: action.error
            }
        //#endregion

        //#region UPDATE_INFO
        case userConstants.UPDATE_INFO_REQUEST:
            return {
                updateInfoRequest: true
            }
        case userConstants.UPDATE_INFO_SUCCESS:
            return {
                updateInfoSuccess: action.result
            }
        case userConstants.UPDATE_INFO_FAILURE:
            return {
                updateInfoFailure: action.error
            }
        //#endregion

        //#region FORGOT_PASSWORD
        case userConstants.FORGOT_PASSWORD_REQUEST:
            return {
                forgotPasswordRequest: true
            }
        case userConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                forgotPasswordSuccess: action.result
            }
        case userConstants.FORGOT_PASSWORD_FAILURE:
            return {
                forgotPasswordFailure: action.error
            }
        //#endregion

        //#region DELETE
        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map(user =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                )
            };
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                items: state.items.filter(user => user.id !== action.id)
            };
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        //#endregion

        //#region UPDATE_TOUR_STATE
        case userConstants.UPDATE_TOUR_STATE_REQUEST:
            return {
                updateTourStateRequest: true
            }
        case userConstants.UPDATE_TOUR_STATE_SUCCESS:
            return {
                updateTourStateSuccess: action.result
            }
        case userConstants.UPDATE_TOUR_STATE_FAILURE:
            return {
                updateTourStateFailure: action.error
            }
        //#endregion

        //#region CHECK_EMAIL
        case userConstants.CHECK_EMAIL_REQUEST:
            return {
                checkEmailRequest: true
            }
        case userConstants.CHECK_EMAIL_SUCCESS:
            return {
                checkEmailSuccess: action.result
            }
        case userConstants.CHECK_EMAIL_FAILURE:
            return {
                checkEmailFailure: action.error
            }
        //#endregion

        case userConstants.CLEAR_USER:
            return {};

        default:
            return state
    }
}