import { authHeader } from '../_helpers/auth-header';
import handleResponse from '../_helpers/responseHandler';

export const configurationService = {
    getConfiguration,
};
let url = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PRODUCTION
// let url = process.env.REACT_APP_API_URL_PRODUCTION
// let url = process.env.REACT_APP_API_URL_DEV

function getConfiguration(keypadId, configType, timezone) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({
            keypadId: keypadId,
            configType: configType,
            timezone: timezone
        })
    };

    return fetch(`${url}/configuration`, requestOptions).then(handleResponse);
}


