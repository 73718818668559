export const keypadConstants = {

    GET_ALL_REQUEST: 'KEYPADS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'KEYPADS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'KEYPADS_GET_ALL_FAILURE',

    UPDATE_KEYPAD_REQUEST: 'UPDATE_KEYPAD_REQUEST',
    UPDATE_KEYPAD_SUCCESS: 'UPDATE_KEYPAD_SUCCESS',
    UPDATE_KEYPAD_FAILURE: 'UPDATE_KEYPAD_FAILURE',

    UPDATE_SEED_REQUEST: 'UPDATE_SEED_REQUEST',
    UPDATE_SEED_SUCCESS: 'UPDATE_SEED_SUCCESS',
    UPDATE_SEED_FAILURE: 'UPDATE_SEED_FAILURE',

    REGISTER_KEYPAD_REQUEST: 'REGISTER_KEYPAD_REQUEST',
    REGISTER_KEYPAD_SUCCESS: 'REGISTER_KEYPAD_SUCCESS',
    REGISTER_KEYPAD_FAILURE: 'REGISTER_KEYPAD_FAILURE',
    
    DELETE_KEYPAD_REQUEST: 'DELETE_KEYPAD_REQUEST',
    DELETE_KEYPAD_SUCCESS: 'DELETE_KEYPAD_SUCCESS',
    DELETE_KEYPAD_FAILURE: 'DELETE_KEYPAD_FAILURE',

    CLEAR_KEYPAD: "CLEAR_KEYPAD"
}