import { message } from "antd";
import { userService } from "../_services/user.service";

export default function handleResponse(response) {
    return response.text().then(text => {
        let data;
        try {
            data = text && JSON.parse(text);
        } catch (error) {
            let err = text.match(/(<pre>)(.*)(<\/pre>)/g)[0];
            if (err) {
                err = err.replace("<pre>", "");
                err = err.replace("</pre>", "");
                err = `Server error: ${err}`;
            }
            data = { success: false, message: err }
        }

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                message.error("Session expired, please try to login again.");
                userService.logout();

                // let location = new Location();
                // location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}