import { alertActions } from './alert.actions';
import { errorHandler } from '../_helpers/errorHandler';
import { configurationConstants } from '../_constants/configuration.constants';
import { configurationService } from '../_services/configuration.service';
import { TimezoneHelper } from '../../helpers/timezoneHelper';

export const configurationActions = {
    getConfiguration,
    clear
};

function getConfiguration(keypadId, configType) {
    return dispatch => {
        return new Promise(async (resolve, reject) => {
            dispatch(request(configType));

            let timezone
            if (configType === 15) {
                timezone = TimezoneHelper.getTimezoneName();
            }

            configurationService.getConfiguration(keypadId, configType, timezone)
                .then(
                    result => {
                        dispatch(success(result.data));
                        resolve(result);
                    },
                    error => {
                        errorHandler(
                            dispatch,
                            error,
                            failure,
                            alertActions.error
                        )
                        reject(error);
                    }
                );
        }).catch(e => {});
    };

    function request(configType) { return { type: configurationConstants.GET_CONFIGURATION_REQUEST, configType } }
    function success(result) { return { type: configurationConstants.GET_CONFIGURATION_SUCCESS, result } }
    function failure(error) { return { type: configurationConstants.GET_CONFIGURATION_FAILURE, error } }
}


function clear() { return { type: configurationConstants.CLEAR_CONFIGURATION } }
