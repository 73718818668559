import { keypadConstants } from '../_constants/keypad.constants';

export function keypads(state = {}, action) {
    switch (action.type) {

        //#region GET_ALL
        case keypadConstants.GET_ALL_REQUEST:
            return {
                getAllRequest: true
            };
        case keypadConstants.GET_ALL_SUCCESS:
            return {
                devices: action.devices
            };
        case keypadConstants.GET_ALL_FAILURE:
            return {
                error: action.error
            };
        //#endregion


        //#region UPDATE_KEYPAD
        case keypadConstants.UPDATE_KEYPAD_REQUEST:
            return {
                updateKeypadRequest: true
            };
        case keypadConstants.UPDATE_KEYPAD_SUCCESS:
            return {
                updateKeypadSuccess: action.result
            };
        case keypadConstants.UPDATE_KEYPAD_FAILURE:
            return {
                error: action.error
            };
        //#endregion

        //#region REGISTER_KEYPAD
        case keypadConstants.REGISTER_KEYPAD_REQUEST:
            return {
                registerKeypadRequest: true
            };
        case keypadConstants.REGISTER_KEYPAD_SUCCESS:
            return {
                registerKeypadSuccess: action.result
            };
        case keypadConstants.REGISTER_KEYPAD_FAILURE:
            return {
                registerKeypadFailure: action.error
            };
        //#endregion
        
        //#region DELETE_KEYPAD
        case keypadConstants.DELETE_KEYPAD_REQUEST:
            return {
                deleteKeypadRequest: true
            };
        case keypadConstants.DELETE_KEYPAD_SUCCESS:
            return {
                deleteKeypadSuccess: true
            };
        case keypadConstants.DELETE_KEYPAD_FAILURE:
            return {
                deleteKeypadFailure: action.error
            };
        //#endregion


        case keypadConstants.CLEAR_KEYPAD:
            return {};


        default:
            return state
    }
}