import { Spin } from "antd";
import styles from "./fullscreenSpinner.module.css"

const FullScreenSpinner = ({ loading, text, background = "#4d4d4d1f" }) =>
    loading ?
        <div className={ styles.fullscreenSpinner } style={{backgroundColor: background}}>
            <Spin size='large' />
            { text && <p className={ styles.text }>{ text }</p> }
        </div>
        : <></>

export default FullScreenSpinner;