import { useLocation, useNavigate } from "react-router-dom"

export const NavigationHelper = () => {
    const location = useLocation()
    const _navigate = useNavigate()

    /**
     * @param {import("react-router-dom").To} to
     * @param {import("react-router-dom").NavigateOptions} options
     */
    navigate = (to, options = {}) => _navigate(to, options)

    toggleBlink = (open) =>{
        navigate(location.pathname, { state: { ...location.state, blinkIsOpen: open } })}

    toggleConfig = (open) =>{
        navigate(location.pathname, { state: { ...location.state, lockerConfigIsOpen: open } })}

    togglePinGeneration = (open) =>{
        navigate(location.pathname, { state: { ...location.state, pinGenerationIsOpen: open } })}

    toggleActivationCodes = (open) =>{
        navigate(location.pathname, { state: { ...location.state, activationCodesIsOpen: open } })
    }

    return null
}

export let navigate;
export let toggleBlink
export let toggleConfig
export let togglePinGeneration
export let toggleActivationCodes 