import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Space } from 'antd';
import { RightOutlined, SettingOutlined } from '@ant-design/icons';
import styles from "./locker.module.css";
import lockerImg from "../../../assets/images/CodeLocker_Graphic_old.png";
import { useDispatch, useSelector } from 'react-redux';
import { codeActions } from '../../../api/_actions/code.actions';
import { keypadActions } from '../../../api/_actions/keypad.actions';
import SwipeableLink from '../../../components/SwipeableLink/swipeableLink';
import Joyride, { ACTIONS } from 'react-joyride';
import { userActions } from '../../../api/_actions/user.actions';
import lockerOptions from "../../../assets/data/lockers";
import freeLockerOptions from "../../../assets/data/freeLocker.js"
import { toggleActivationCodes, toggleConfig, togglePinGeneration } from '../../../helpers/navigationHelper';
import { useLocation } from 'react-router-dom';

function Locker({ device, isActiveDevice }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.authentication.user);

    const [showTour, setShowTour] = useState(false);

    const openConfigRef = useRef(null);
    const activationCodesRef = useRef(null);
    const generateCodeButtonRef = useRef(null);
    const navRef = useRef(document.querySelector(".nav-blink"));

    const {
        lockerConfigIsOpen,
    } = useLocation().state || {}

    function standardGuideCallback(params) {
        let { action, step } = params;

        if (!step.parent) {
            step.parent = step.target.closest(`div[class~='${styles.wrapper}']`);
        }

        if (step.target && action === ACTIONS.UPDATE) {
            step.parent.scrollTop = step.target.offsetTop;
        }

        if (action === ACTIONS.RESET) {
            setShowTour(false);
            dispatch(userActions.updateTourState("standardGuide", true));
        }
    }

    const standardGuideSteps = [
        {
            target: openConfigRef.current,
            content: "Press here to configure your device",
            disableBeacon: true,
            spotlightPadding: 0,
            placement: "left"
        },
        {
            target: activationCodesRef.current,
            content: "Press here to see activation codes",
            disableBeacon: true,
            placement: "top"
        },
        {
            target: generateCodeButtonRef.current,
            content: "Press here to generate timed codes",
            disableBeacon: true,
            placement: "auto"
        },
        {
            target: navRef.current,
            content: "Press here to program (BLINK) your device",
            disableBeacon: true,
            placement: "auto",
            spotlightPadding: 0,
            parent: document.body
        },
    ]

    useEffect(() => {
        if (!user.tours.standardGuideCompleted) {
            if (lockerConfigIsOpen) {
                toggleConfig(false)
            }
            let timeout = setTimeout(() => {
                setShowTour(true);
            }, 300);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, []) // eslint-disable-line

    return (
        <div className={ styles.wrapper }>
            { showTour && isActiveDevice &&
                <Joyride continuous showProgress showSkipButton disableScrolling hideCloseButton
                    callback={ standardGuideCallback }
                    steps={ standardGuideSteps }
                    run={ user.tours.startupGuideCompleted && !user.tours.standardGuideCompleted }
                />
            }
            <div className="locker">
                <img className="lockerImage" src={ lockerImg } alt="Locker">

                </img>
                <div className="lockerInfo" >
                    <div className="lockerName">
                        <div>
                            <p>{ device.keypadCustomName }</p>
                            <p className='lockerId'>@{ device.keypadId }</p>
                        </div>
                        <SettingOutlined className="openConfig" ref={ openConfigRef } onClick={ () => toggleConfig(true) } />
                    </div>
                    <p className="lockerDescription">{ device.keypadComment }</p>
                </div>
            </div>
            <Space direction='vertical' size={ 15 } style={ { margin: "15px 0" } }>

                <Row gutter={ 20 } justify="space-between" wrap={ false }>
                    <Col>Selected Locker</Col>
                    <Col>
                        { lockerOptions().find(option => option?.value === device.keypadLocker)?.label || "Not found" }
                    </Col>
                </Row>

                <SwipeableLink to="" linkRef={ activationCodesRef } style={ { margin: "-10px 0px", padding: "10px 0px", display: "block" } }
                    className="activationCodes" // Only used for tour
                    onClick={ () => toggleActivationCodes(true) }>
                    <Row gutter={ 20 } justify="space-between" wrap={ false }>
                        <Col>List of Activation Codes</Col>
                        <Col><RightOutlined /></Col>
                    </Row>
                </SwipeableLink>

                <Row gutter={ 20 } justify="space-between" wrap={ false }>
                    <Col>Service Configuration Code</Col>
                    <Col>{ device.keypadServiceConfCode }</Col>
                </Row>

                <Row gutter={ 20 } justify="space-between" wrap={ false }>
                    <Col>User Configuration Code</Col>
                    <Col>{ device.keypadUserConfCode }</Col>
                </Row>

                <p>Silent Mode { device.keypadSilent ? "ON" : "OFF" }</p>

                <p>
                    { freeLockerOptions().find(option => {
                        let freeLocker = device.keypadFreeLocker;

                        let value =
                            freeLocker === true ? 1 :
                                freeLocker === false ? 0 :
                                    freeLocker

                        return option?.value === value
                    })?.label || "Not found" }
                </p>

                { ![true, 1].includes(device.keypadFreeLocker) && // Not a free locker
                    <p>{ `${device.keypadHigSecurity ? "High" : "Low"} Security Timed Code` }</p>
                }

            </Space>
            <Button type='primary' size='large' block ref={ generateCodeButtonRef } style={ { marginTop: 20 } }
                disabled={ [true, 1].includes(device.keypadFreeLocker) }
                onClick={ () => {
                    if (!device.keypadOneTimeCodes || device.keypadOneTimeCodes.length === 0) {
                        return togglePinGeneration(true)
                    }
                    dispatch(codeActions.deleteOldOneTimeCodes(device.keypadId)).then((success) => {
                        if (success) {
                            dispatch(keypadActions.getAll()).then(() => {
                                togglePinGeneration(true)
                            })
                        } else {
                            togglePinGeneration(true)
                        }
                    })
                } } >GENERATE TIMED PIN CODE</Button>
        </div>
    );
}

export default Locker;