import { Navigate, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useNavigate, useRouteError } from "react-router-dom";
import RegisterLocker from "./pages/RegisterLocker/registerLocker";
import PageNotFound from './pages/PageNotFound/pageNotFound';
import UserSettings from "./pages/UserSettings/userSettings";
import { getToken, renewCookies } from "./helpers/sessionHelper";
import SignUp from "./pages/SignUp/signUp";
import Lockers from './pages/Lockers/lockers';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from 'react';
import Login from "./pages/Login/login";
import Nav from './components/Nav/nav';
import Main from "./pages/Main/main";
import './App.css';
import { message } from "antd";
import ForgotPassword from "./pages/ForgotPassword/forgotPassword";
import Devices from "./pages/Devices/devices";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsAndConditions from "./pages/termsAndConditions";
import { userActions } from "./api/_actions/user.actions";
import FullScreenSpinner from "./components/FullscreenSpinner/fullscreenSpinner";
import ServiceWorkerAlert from "./components/ServiceWorkerAlert/serviceWorkerAlert";
import { NavigationHelper, navigate } from "./helpers/navigationHelper";
import dayjs from "dayjs"
import updateLocale from "dayjs/plugin/updateLocale"

function App() {

    dayjs.extend(updateLocale)
    dayjs.updateLocale("en", {
        weekStart: 1
    })

    const routes = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route element={ <Helpers /> } >
                    <Route path="" element={ <Main /> } />
                    <Route path="login" element={ <Meta title="Login" > <Login /></Meta > } />
                    <Route path="sign-up" element={ < Meta title="Sign Up" > <SignUp /></ Meta> } />
                    <Route path="forgot-password" element={ < Meta title="Forgot password" > <ForgotPassword /></ Meta> } />
                    <Route path="privacy-policy" element={ < Meta title="Privacy policy" > <PrivacyPolicy /></ Meta> } />
                    <Route path="terms-and-conditions" element={ < Meta title="Terms and Conditions" > <TermsAndConditions /></ Meta> } />

                    <Route path="" element={ < Footer /> } >
                        <Route path="lockers/:id?" element={ <Meta title="Lockers" ><Lockers /></Meta> } />
                        <Route path="register-locker" element={ <Meta title="Register Locker" ><RegisterLocker /></Meta> } />
                        <Route path="devices" element={ <Meta title="Devices" ><Devices /></Meta> } />
                        <Route path="user-settings" element={ <Meta title="User settings" ><UserSettings /></Meta> } />
                    </Route>

                    <Route path="*" element={ <PageNotFound /> } />
                </Route>
            </>
        )
    )

    return (<RouterProvider router={ routes } />)
}

function Helpers() {
    const alert = useSelector(state => state.alert);
    const authState = useSelector(state => state.authentication);
    const dispatch = useDispatch()

    const token = getToken();
    const [createMessage, messageContext] = message.useMessage()

    useEffect(() => {
        renewCookies();

        if (token && !authState.user) {
            dispatch(userActions.getUserInfo());
        }
    }, []) //eslint-disable-line

    useEffect(() => {
        if (alert.message && alert.type) {
            alert.type === "success"
                ? createMessage.success(alert.message)
                : createMessage.error(alert.message)
        }
    }, [alert]) // eslint-disable-line

    useEffect(() => {
        if (authState.user && window.location.pathname === "/")
            navigate("/lockers")
    }, [authState.user]) // eslint-disable-line

    if ((token && !authState.user) || authState.gettingUserInfo)
        return <FullScreenSpinner loading={ true } text="Logging in..." />

    return (
        <>
            <NavigationHelper />
            <ServiceWorkerAlert />
            <Outlet />
            { messageContext }
        </>
    )
}


function Footer() {
    const token = getToken();

    const authState = useSelector(state => state.authentication);

    if (!token && !authState.user)
        return <Navigate to="/" />;

    return (
        <div className='nav-container'>
            <div className='content' >
                <Outlet />
            </div>
            <Nav />
        </div>
    );
}

function Meta({ children, title }) {
    const documentTitle = process.env.REACT_APP_DOCUMENT_TITLE;

    document.title = (title ? title + " - " : "") + documentTitle;

    return children;
}

export default App;
