import { userConstants } from '../_constants/user.constants';

export function authentication(state = {}, action) {
    switch (action.type) {

        // #region LOGIN
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loginFailure: action
            };
        // #endregion

        // #region GET_USER_INFO_REQUEST
        case userConstants.GET_USER_INFO_REQUEST:
            return {
                gettingUserInfo: true,
            };
        case userConstants.GET_USER_INFO_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.GET_USER_INFO_FAILURE:
            return {
                loginFailure: action
            };
        // #endregion

        case userConstants.UPDATE_AUTH:
            return {
                loggedIn: state.loggedIn,
                user: action.userInfo
            }

        case userConstants.LOGOUT:
            return {};

        case userConstants.CLEAR_AUTHENTICATION:
            return {};

        default:
            return state
    }
}