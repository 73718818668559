import { deleteCookies, getUser, saveToken } from '../../helpers/sessionHelper';
import { authHeader } from '../_helpers/auth-header';
import { userActions } from "../_actions/user.actions";
import { keypadActions } from '../_actions/keypad.actions';
import { codeActions } from '../_actions/code.actions';
import store from "../_helpers/store";
import handleResponse from '../_helpers/responseHandler';
import { navigate } from '../../helpers/navigationHelper';

export const userService = {
    login,
    logout,
    register,
    changePassword,
    updateInfo,
    getUserInfo,
    forgotPassword,
    updateTourState,
    update,
    checkEmail,
    delete: _delete
};
let url = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PRODUCTION

function login(email, password) {
    if (email) {
        email = email.toLocaleLowerCase();
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${url}/users/login`, requestOptions)
        .then(handleResponse)
        .then(response => {
            saveToken(response.authToken);

            return response;
        });
}

function logout() {
    navigate("/")

    deleteCookies();

    store.dispatch(keypadActions.clear())
    store.dispatch(userActions.clearAll())
    store.dispatch(codeActions.clear())
}

function getUserInfo(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${url}/users/user`, requestOptions).then(handleResponse);
}

function register(user) {
    if (user.email) {
        user.email = user.email.toLocaleLowerCase();
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${url}/users/register-user`, requestOptions).then(handleResponse);
}

function changePassword(password) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({ password })
    };

    return fetch(`${url}/users/change-password`, requestOptions).then(handleResponse);
}

function updateInfo(prop, value) {
    const user = getUser();
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({
            userId: user.userId,
            prop: prop,
            value: value
        })
    };

    return fetch(`${url}/users/update-info`, requestOptions).then(handleResponse);
}

function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };

    return fetch(`${url}/users/forgot-password`, requestOptions).then(handleResponse);
}


function updateTourState(tourName, state) {
    const user = getUser();

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({
            userId: user.userId,
            tourName: tourName,
            state: state
        })
    };

    return fetch(`${url}/users/update-tour-state`, requestOptions).then(handleResponse);
}

function checkEmail(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };

    return fetch(`${url}/users/check-email`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${url}/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${url}/users/${id}`, requestOptions).then(handleResponse);
}
