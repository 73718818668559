import React, { useEffect, useState } from 'react';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Popconfirm, Row, Select, Space, Switch } from 'antd';
import { Link } from 'react-router-dom';
import styles from "./lockerConfiguration.module.css";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch, useSelector } from 'react-redux';
import { keypadActions } from '../../../../api/_actions/keypad.actions';
import rules from "../../../../helpers/validation/keypadValidation";
import FullScreenSpinner from '../../../../components/FullscreenSpinner/fullscreenSpinner';
import { removeNonDigits } from '../../../../helpers/helperFunctions';
import lockerImg from "../../../../assets/images/CodeLocker_Graphic_old.png";
import { callFunctionActions } from '../../../../api/_actions/callFunction.actions';
import freeLockerOptions from "../../../../assets/data/freeLocker.js";
import { TimezoneHelper } from '../../../../helpers/timezoneHelper';
import CollapseContent from '../../../../components/CollapseContent/collapseContent';
import { navigate, toggleActivationCodes, toggleBlink, toggleConfig } from '../../../../helpers/navigationHelper';

function LockerConfiguration({ device }) {
    const dispatch = useDispatch();

    let keypadState = useSelector(state => state.keypads);
    let callFunction = useSelector(state => state.callFunction);

    const [selectedDevice, setSelectedDevice] = useState(() => {
        let temp = cloneDeep(device)
        temp.keypadUserConfCode = temp.keypadUserConfCode + "";
        temp.keypadServiceConfCode = temp.keypadServiceConfCode + "";
        temp.keypadSeed = temp.keypadHigSecurity ? "" : temp.keypadSeed + "";
        temp.keypadFreeLocker =
            temp.keypadFreeLocker === true ? 1 :
                temp.keypadFreeLocker === false ? 0 :
                    temp.keypadFreeLocker;

        return temp;
    });

    function openActivationCodesDrawer() {
        toggleActivationCodes(true)
    }

    function updateDevice(value, prop) {
        setSelectedDevice(prev => {
            return {
                ...prev,
                [prop]: value
            }
        })
    }

    function closeConfig() {
        navigate(-1)
        setSelectedDevice(cloneDeep(device));
    }

    function onSubmit(values) {
        if ([true, 1].includes(values.keypadFreeLocker)) {
            values.keypadHigSecurity = true;
        }
        let newKeypad = {
            ...selectedDevice,
            ...values
        }

        dispatch(keypadActions.updateKeypad(newKeypad)).then((result) => {
            if (result) {
                dispatch(keypadActions.getAll()).then(() => {
                    toggleBlink(true)
                });
            }
        });
        setSelectedDevice(newKeypad);
    }

    const [form] = Form.useForm();
    // const keypadSilent = Form.useWatch('keypadSilent', form);
    const keypadFreeLocker = Form.useWatch('keypadFreeLocker', form);
    const keypadHigSecurity = Form.useWatch('keypadHigSecurity', form);

    useEffect(() => {
        if (callFunction.function === "lockerConfigurationSubmit") {
            form.submit();
            dispatch(callFunctionActions.clear());
        }
    }, [callFunction]) // eslint-disable-line

    const deleteDevice = async () => {
        dispatch(keypadActions.deleteKeypad(device)).then((result) => {
            if (result) {
                dispatch(keypadActions.getAll()).then(() => {
                    navigate("./lockers")
                });
                toggleConfig(false)
            }
        })
    }

    return (
        <div className={ styles.wrapper }>
            <div className="locker" >
                <img className="lockerImage" src={ lockerImg } alt="Locker">

                </img>
                <div className="lockerInfo">
                    <div className="lockerName">
                        <div>
                            <Form.Item noStyle rules={ rules } >
                                <Input.TextArea maxLength={ 20 } spellCheck={ false } autoSize={ { maxRows: 1 } } size="small" value={ selectedDevice.keypadCustomName }
                                    onChange={ e => updateDevice(e.target.value, "keypadCustomName") } />
                            </Form.Item>

                            <p className='lockerId'>@{ selectedDevice.keypadId }</p>
                        </div>
                        <CloseOutlined className="closeConfig" onClick={ () => closeConfig() } />
                    </div>
                    <Form.Item noStyle rules={ rules } >
                        <Input.TextArea maxLength={ 100 } spellCheck={ false } autoSize={ { minRows: 1, maxRows: 2 } } size="small" value={ selectedDevice.keypadComment }
                            onChange={ e => updateDevice(e.target.value, "keypadComment") } />
                    </Form.Item>
                </div>
            </div>
            <Form className={ [styles.form, "configForm"].join(" ") } colon={ false } labelAlign="left" form={ form } layout="horizontal" onFinish={ onSubmit } requiredMark={ false } validateTrigger="onSubmit"
                initialValues={
                    {
                        ...selectedDevice,
                        time: {
                            timezone: selectedDevice.time?.timezone || TimezoneHelper.getTimezoneName()
                        }
                    }

                }
                labelWrap
                labelCol={ { flex: "1 1 100px" } }
            >
                <Form.Item wrapperCol={ { flex: "0px" } } className={ styles.codesLink } label={
                    <Link to=""
                        onClick={ (e) => { e.preventDefault(); openActivationCodesDrawer() } }>
                        <Row gutter={ 20 } justify="space-between" wrap={ false }>
                            <Col>List of Activation Codes</Col>
                            <Col><RightOutlined /></Col>
                        </Row>
                    </Link>
                }>

                </Form.Item>

                <Form.Item name="keypadServiceConfCode" rules={ rules.keypadServiceConfCode } wrapperCol={ { flex: "95px" } } label="Service Configuration Code">
                    <Input size="small" maxLength={ 8 } inputMode="numeric" form={ form }
                        onChange={ (e) => form.setFieldsValue({ keypadServiceConfCode: removeNonDigits(e.target.value) }) } />
                </Form.Item>

                <Form.Item name="keypadUserConfCode" rules={ rules.keypadUserConfCode } wrapperCol={ { flex: "95px" } } label="User Configuration Code">
                    <Input maxLength={ 8 } size="small" form={ form } inputMode="numeric"
                        onChange={ (e) => form.setFieldsValue({ keypadUserConfCode: removeNonDigits(e.target.value) }) } />
                </Form.Item>

                <Form.Item name="keypadSilent" label="Silent Mode" valuePropName="checked" wrapperCol={ { flex: "44px" } } >
                    <Switch />
                </Form.Item>

                <Form.Item label="Locker mode" name="keypadFreeLocker" wrapperCol={ { flex: "160px" } }>
                    <Select disabled={ device.keypadLocker === 4 } options={ freeLockerOptions() } />
                </Form.Item>

                <CollapseContent show={ ![undefined, true, 1].includes(keypadFreeLocker) }>
                    <Form.Item label="Timed Code Security" name="keypadHigSecurity" wrapperCol={ { flex: "160px" } }>
                        <Select options={ [
                            { value: true, label: "High" },
                            { value: false, label: "Low" }
                        ] } />
                    </Form.Item>

                    <CollapseContent show={ !keypadHigSecurity }>
                        <Form.Item name="keypadSeed" label="User Seed" rules={ !!keypadHigSecurity ? null : rules.keypadSeed } wrapperCol={ { flex: "95px" } }>
                            <Input form={ form } size="small" inputMode='numeric' maxLength={ 8 }
                                onChange={ (e) => form.setFieldsValue({ keypadSeed: removeNonDigits(e.target.value) }) } />
                        </Form.Item>
                    </CollapseContent>
                </CollapseContent>
            </Form>

            <Space align='end' direction='vertical'>
                <Popconfirm
                    title="Are you sure you want to delete this device?"
                    okText="Yes"
                    cancelText="No"
                    arrow={ { pointAtCenter: true } }
                    autoAdjustOverflow
                    placement='topRight'
                    onConfirm={ () => deleteDevice() }
                >
                    <Button type='primary' style={ { marginTop: "30px" } } danger size='small' loading={ keypadState.deleteKeypadRequest } >DELETE DEVICE</Button>
                </Popconfirm>
            </Space>

            <FullScreenSpinner loading={ keypadState.updateKeypadRequest || keypadState.updateSeedRequest || keypadState.deleteKeypadRequest } />
        </div>
    );
}

export default LockerConfiguration;