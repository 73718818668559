import { Button, Drawer, List } from "antd"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";

/**
 * Hook for controlling sorting drawer
 * @param {Object[]} options 
 * @param {string} options.prop Property name
 * @param {string} options.name Human readable name
 * @param {string} options.order Order of sorting
 * @param {Function} setOption 
 * @param {string} getContainer 
 * @returns 
 */
function useSortDrawer(options, setOption, getContainer = "#root .content") {
    const [open, setOpen] = useState(false)
    const location = useLocation()

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    useEffect(() => closeDrawer(), [location])

    return [
        <Drawer
            placement='bottom'
            title="Sort by:"
            className="sortDrawer"
            destroyOnClose={ true }
            push={ false }
            bodyStyle={ { padding: 0 } }
            height={ 157 }
            open={ open }
            getContainer={ getContainer }
            onClose={ closeDrawer }
            contentWrapperStyle={ { width: "100%", padding: "0px" } }
        >
            <List dataSource={ options } renderItem={ option =>
                <List.Item>
                    <Button type='text' size="large" block style={ { textAlign: "left" } }
                        onClick={ () => {
                            setOption(option);
                            closeDrawer()
                        } }
                    >
                        { option.name }
                    </Button>
                </List.Item> }>
            </List>
        </Drawer>,
        openDrawer
    ]
}

export default useSortDrawer