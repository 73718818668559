import { Button, Form, Modal, Input, Select, Switch, Row, Col, Collapse, Drawer } from "antd";
import styles from "./registerLocker.module.css";
import rules from "../../helpers/validation/keypadValidation";
import { LeftOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { keypadActions } from "../../api/_actions/keypad.actions";
import { removeNonDigits } from "../../helpers/helperFunctions";
import { useEffect } from "react";
import lockerOptions from "../../assets/data/lockers.js";
import freeLockerOptions from "../../assets/data/freeLocker.js";
import customLockerHBEdgesOptions from "../../assets/data/customLocker/hbEdges";
import customLockerHBPolarityOptions from "../../assets/data/customLocker/hbPolarity";
// import customLockerOCFeedbackTrigger from "../../assets/data/customLocker/ocFeedbackTriggerEdge";
// import customLockerOCPolarity from "../../assets/data/customLocker/ocPolarity";
import CollapseContent from "../../components/CollapseContent/collapseContent";
import { TimezoneHelper } from "../../helpers/timezoneHelper";
import { navigate, toggleBlink } from "../../helpers/navigationHelper";
import Blinker from "../Lockers/Locker/LockerConfiguration/Blinker/blinker";
import { useLocation } from "react-router-dom";



function RegisterLocker() {
    const dispatch = useDispatch();

    const [createModal, modalContext] = Modal.useModal()

    const [form] = Form.useForm();

    const {
        blinkIsOpen
    } = useLocation().state || {}


    const registerKeypadSuccess = useSelector(state => state.keypads?.registerKeypadSuccess);
    const keypadState = useSelector(state => state.keypads);

    const keypadHigSecurity = Form.useWatch('keypadHigSecurity', form) ?? true;
    const keypadUserCodes = Form.useWatch('keypadUserCodes', form) || [];
    const keypadFreeLocker = Form.useWatch('keypadFreeLocker', form);
    const keypadLocker = Form.useWatch('keypadLocker', form);

    useEffect(() => {
        if (keypadState.registerKeypadFailure) {
            form.setFields([
                {
                    name: keypadState.registerKeypadFailure.field,
                    errors: [keypadState.registerKeypadFailure.message],
                },
            ]);
        }
    }, [keypadState.registerKeypadFailure]) // eslint-disable-line

    useEffect(() => {
        if (registerKeypadSuccess?.success) {
            createModal.confirm({
                title: <h3>Do you want to configure now?</h3>,
                icon: null,
                okText: "Yes",
                cancelText: "No",
                centered: true,
                className: styles.configNow,
                onOk: () => toggleBlink(true)
            })
        }
    }, [registerKeypadSuccess]) // eslint-disable-line

    const initialValues = {
        keypadCustomName: "XS4 Code Locker",
        keypadLocker: 1,
        keypadServiceConfCode: "12347890",
        keypadUserConfCode: "4711",
        keypadSilent: false,
        keypadHigSecurity: true,
        keypadFreeLocker: 0,
        keypadComment: "",
        keypadUserCodes: [
            {
                code: "1234",
                name: "Default User"
            }
        ],
        timezone: TimezoneHelper.getTimezoneName(),

        // * H-bridge settings
        // hBridge: {
        //     batteryMonitor: 0,
        //     openPolarity: 0,
        //     closePolarity: 0,
        //     openEdge: 0,
        //     closeEdge: 0,
        //     openPeriodeTime: 10000,
        //     openDutyTime: 10000,
        //     closePeriodeTime: 10000,
        //     closeDutyTime: 10000,
        // },

        // * Open collector settings
        // openCollector: {
        //     batteryMonitor: 0,
        //     feedbackTriggerEdge: 0
        // }
    }

    function onSubmit(device) {
        if (device.keypadHigSecurity === undefined)
            device.keypadHigSecurity = true;

        dispatch(keypadActions.registerKeypad(device));
    }

    function updateUserCodes(value, prop, index) {
        let userCodes = [...keypadUserCodes]
        prop === "code"
            ? userCodes[index].code = removeNonDigits(value || "")
            : userCodes[index].name = (value || "");

        form.setFieldsValue({ keypadUserCodes: userCodes })
    }

    return (
        <div className={ styles.wrapper }>
            <h1 className={ styles.header }>Register Locker</h1>
            <Form layout="horizontal" className="configForm" form={ form } onFinish={ onSubmit } requiredMark={ false } validateTrigger="onSubmit" initialValues={ initialValues }
                labelWrap labelCol={ { flex: "1 1 100px" } } labelAlign="left" colon={ false }
            >
                <Form.Item name="keypadCustomName" rules={ rules.keypadCustomName } label="Keypad name" wrapperCol={ { flex: "160px" } }>
                    <Input type="text" maxLength={ 20 } form={ form } size="small" />
                </Form.Item>

                <Form.Item name="keypadLocker" label="Locker Selection" disabled wrapperCol={ { flex: "160px" } }>
                    <Select popupMatchSelectWidth={ false } options={ lockerOptions() }
                        onChange={ (value) => value === 4 ? form.setFieldsValue({ keypadFreeLocker: 0, keypadHigSecurity: true }) : null } />
                </Form.Item>

                {/* 
                <CollapseContent show={ keypadLocker === 3 } style={ { marginBottom: "15px" } }>

                    <Collapse defaultActiveKey={ ['1', '2'] } className={ styles.customLockerSettings }>
                        <Collapse.Panel key="1" header="H-Bridge" >

                            <Form.Item name={ ["hBridge", "batteryMonitor"] } label="Battery monitor" valuePropName="checked" wrapperCol={ { flex: "44px" } } >
                                <Switch onChange={ (value) => form.setFieldsValue({ "hBridge.batteryMonitor": value }) } />
                            </Form.Item>

                            <Form.Item name={ ["hBridge", "openPolarity"] } wrapperCol={ { flex: "160px" } } label="Open polarity">
                                <Select popupMatchSelectWidth={ false } options={ customLockerHBPolarityOptions() }
                                    onChange={ (value) => form.setFieldsValue({ "hBridge.openPolarity": value }) } />
                            </Form.Item>

                            <Form.Item name={ ["hBridge", "closePolarity"] } wrapperCol={ { flex: "160px" } } label="Close polarity">
                                <Select popupMatchSelectWidth={ false } options={ customLockerHBPolarityOptions() }
                                    onChange={ (value) => form.setFieldsValue({ "hBridge.closePolarity": value }) } />
                            </Form.Item>

                            <Form.Item name={ ["hBridge", "openEdge"] } wrapperCol={ { flex: "160px" } } label="Open edge">
                                <Select popupMatchSelectWidth={ false } options={ customLockerHBEdgesOptions() }
                                    onChange={ (value) => form.setFieldsValue({ "hBridge.openEdge": value }) } />
                            </Form.Item>

                            <Form.Item name={ ["hBridge", "closeEdge"] } wrapperCol={ { flex: "160px" } } label="Close edge">
                                <Select popupMatchSelectWidth={ false } options={ customLockerHBEdgesOptions() }
                                    onChange={ (value) => form.setFieldsValue({ "hBridge.closeEdge": value }) } />
                            </Form.Item>

                            <Form.Item name={ ["hBridge", "openPeriodeTime"] } wrapperCol={ { flex: "95px" } } label="Open periode time (ms)">
                                <Input type="text" maxLength={ 5 } form={ form } inputMode="numeric" size="small"
                                    onChange={ (e) => form.setFieldsValue({ "hBridge.openPeriodeTime": removeNonDigits(e.target.value) }) } />
                            </Form.Item>

                            <Form.Item name={ ["hBridge", "closePeriodeTime"] } wrapperCol={ { flex: "95px" } } label="Close periode time (ms)">
                                <Input type="text" maxLength={ 5 } form={ form } inputMode="numeric" size="small"
                                    onChange={ (e) => form.setFieldsValue({ "hBridge.closePeriodeTime": removeNonDigits(e.target.value) }) } />
                            </Form.Item>

                            <Form.Item name={ ["hBridge", "openDutyTime"] } wrapperCol={ { flex: "95px" } } label="Open duty time (ms)">
                                <Input type="text" maxLength={ 5 } form={ form } inputMode="numeric" size="small"
                                    onChange={ (e) => form.setFieldsValue({ "hBridge.openDutyTime": removeNonDigits(e.target.value) }) } />
                            </Form.Item>

                            <Form.Item name={ ["hBridge", "closeDutyTime"] } wrapperCol={ { flex: "95px" } } label="Close duty time (ms)">
                                <Input type="text" maxLength={ 5 } form={ form } inputMode="numeric" size="small"
                                    onChange={ (e) => form.setFieldsValue({ "hBridge.closeDutyTime": removeNonDigits(e.target.value) }) } />
                            </Form.Item>

                        </Collapse.Panel>

                        <Collapse.Panel key="2" header="Open collector" >

                            <Form.Item name={ ["OC", "batteryMonitor"] } label="Battery monitor" valuePropName="checked" wrapperCol={ { flex: "44px" } } >
                                <Switch onChange={ (value) => form.setFieldsValue({ "OC.batteryMonitor": value }) } />
                            </Form.Item>

                        </Collapse.Panel>
                    </Collapse>

                </CollapseContent> 
                */}


                <Form.Item name="keypadServiceConfCode" rules={ rules.keypadServiceConfCode } wrapperCol={ { flex: "95px" } } label="Service Configuration Code">
                    <Input type="text" maxLength={ 8 } form={ form } inputMode="numeric" size="small"
                        onChange={ (e) => form.setFieldsValue({ keypadServiceConfCode: removeNonDigits(e.target.value) }) } />
                </Form.Item>

                <Form.Item name="keypadUserConfCode" label="User Configuration Code" wrapperCol={ { flex: "95px" } } rules={ rules.keypadUserConfCode }>
                    <Input type="text" maxLength={ 8 } form={ form } inputMode="numeric" size="small"
                        onChange={ (e) => form.setFieldsValue({ keypadUserConfCode: removeNonDigits(e.target.value) }) } />
                </Form.Item>

                <Form.Item name="keypadSilent" label="Silent Mode" valuePropName="checked" wrapperCol={ { flex: "44px" } } >
                    <Switch />
                </Form.Item>

                <Form.Item label="Locker mode" name="keypadFreeLocker" wrapperCol={ { flex: "160px" } } >
                    <Select className='test' disabled={ keypadLocker === 4 } options={ freeLockerOptions() } />
                </Form.Item>

                {/* Hide option if free locker */ }
                <CollapseContent show={ ![undefined, 1].includes(keypadFreeLocker) }>
                    <Form.Item label="Timed Code Security" name="keypadHigSecurity" wrapperCol={ { flex: "160px" } }>
                        <Select className='test' options={ [
                            { value: true, label: "High" },
                            { value: false, label: "Low" }
                        ] } />
                    </Form.Item>

                    {/* Hide option if high security */ }
                    <CollapseContent show={ !keypadHigSecurity }>
                        <Form.Item name="userSeed" label="User Seed" rules={ keypadHigSecurity ? null : rules.keypadSeed } wrapperCol={ { flex: "95px" } }>
                            <Input type="text" maxLength={ 8 } form={ form } inputMode="numeric" size="small"
                                onChange={ (e) =>
                                    form.setFieldsValue({ userSeed: removeNonDigits(e.target.value) })
                                } />
                        </Form.Item>
                    </CollapseContent>
                </CollapseContent>

                <Form.Item label="User Codes" className={ styles.vertical }>
                    <Form.List name="keypadUserCodes" >
                        { (fields, { add, remove }) => (
                            <>
                                { fields.map(({ key, name, ...props }) => {
                                    return (
                                        <Row gutter={ [8] } wrap={ false } style={ { marginBottom: "15px", alignItems: "baseline" } } key={ key }>
                                            <Col flex="auto" >
                                                <Form.Item name={ [name, 'code'] } className={ styles.userCode } rules={ rules.keypadUserCode.code }>
                                                    <Input type="text" maxLength={ 8 } inputMode="numeric" size="small"
                                                        onChange={ (e) => updateUserCodes(e.target.value, "code", name) } />
                                                </Form.Item>
                                            </Col>
                                            <Col flex="auto">
                                                <Form.Item name={ [name, 'name'] } className={ styles.userCode } rules={ [
                                                    ...rules.keypadUserCode.name,
                                                    () => ({
                                                        validator(_, value) {
                                                            return [...keypadUserCodes].filter(x => x.name === value).length >= 2
                                                                ? Promise.reject(new Error('No duplicate names'))
                                                                : Promise.resolve()
                                                        }
                                                    }),
                                                ] }>
                                                    <Input type="text" maxLength={ 20 } form={ form } size="small"
                                                        onChange={ (e) => updateUserCodes(e.target.value, "name", name) } />
                                                </Form.Item>
                                            </Col>
                                            <Col flex="1.7rem"><MinusCircleOutlined onClick={ () => remove(name) } style={ { fontSize: "1.2rem" } } /></Col>
                                        </Row>
                                    )
                                }) }
                                { (() => {
                                    let userCodes = [...keypadUserCodes];

                                    let lastCode = userCodes[userCodes.length - 1];
                                    if (userCodes.length === 0 || (userCodes.length < 200 && lastCode?.code && lastCode?.name)) {
                                        return (
                                            <Button type="dashed" className={ styles.addCodeButton } onClick={ () => add({ code: "", name: "" }) } block icon={ <PlusOutlined /> }>
                                                Add User Code
                                            </Button>
                                        )
                                    }
                                })() }
                            </>
                        ) }
                    </Form.List>
                </Form.Item>

                <Form.Item name="timezone" label="Timezone" wrapperCol={ { flex: "160px" } } required>
                    <Select placeholder="Select timezone"
                        popupMatchSelectWidth={ false }
                        options={ TimezoneHelper.getAllTimezones().map(timezone => {
                            return {
                                label: timezone,
                                value: timezone
                            }
                        }) }
                    />
                </Form.Item>


                <Form.Item name="keypadComment" label="Comment" className={ styles.vertical }>
                    <Input.TextArea maxLength={ 100 } autoSize size="small" />
                </Form.Item>

                <Form.Item>
                    <Button type='primary' size="large" htmlType='submit' block>REGISTER LOCKER</Button>
                </Form.Item>
            </Form>

            <Drawer
                destroyOnClose={ true }
                getContainer="#root .content"
                className='drawer-header-center'
                title="Configure Device"
                headerStyle={ { textAlign: "center" } }
                closeIcon={ <LeftOutlined /> }
                onClose={ () => navigate(-1) }
                open={ blinkIsOpen }
                closable={ true }
                contentWrapperStyle={ { width: "100%", padding: "0px" } }
                bodyStyle={ { padding: "0px" } }>

                <Blinker device={ registerKeypadSuccess?.body } />
            </Drawer>

            { modalContext }
        </div>
    )
}

export default RegisterLocker;