const userValidation = {
    keypadCustomName: [
        {
            required: true,
            message: "Name is required"
        },
        {
            max: 20,
            message: "Can't be longer than 20 characters"
        }
    ],
    keypadServiceConfCode: [
        {
            required: true,
            message: "Please input service configuration code"
        },
        {
            len: 8, 
            message: "Must be 8 characters"
        }
    ],
    keypadUserConfCode: [
        {
            required: true,
            message: "Please input user configuration code"
        },
        {
            min: 4, 
            message: "Must be at least 4 characters"
        },
        {
            max: 8, 
            message: "Can't be longer than 8 characters"
        }
    ],
    keypadSeed: [
        {
            required: true,
            message: "Please input seed"
        },
        {
            min: 8,
            message: "Must be 8 characters"
        },
        {
            pattern: /^(?![0]).*/,
            message: "Can't start with 0"
        }
    ],
    keypadUserCode: {
        code: [
            {
                required: true,
                message: "Code is required"
            },
            {
                min: 4,
                message: "Must be at least 4 characters"
            },
            {
                max: 8,
                message: "Can't be longer than 8 characters"
            }
        ],
        name: [
            {
                required: true,
                message: "Name is required"
            },
            {
                max: 20,
                message: "Can't be longer than 20 characters"
            }
        ]
    }
}

export default userValidation;