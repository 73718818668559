const steps = [
    {
        target: "div[class^='lockers_devicesContainer']",
        content: "You Code Lockers will be shown in this area",
        disableBeacon: true,
        spotlightPadding: 0,
    },
    {
        target: ".nav-devices",
        content: "Here you can manage and search for devices",
        disableBeacon: true,
        spotlightPadding: 0,
        placement: "top"
    },
    {
        target: ".nav-blink",
        content: "Press here to save and program (BLINK) your device, or return to LOCKERS page",
        disableBeacon: true,
        spotlightPadding: 0,
        placement: "top"
    },
    {
        target: ".nav-more",
        content: "Press here to manage your account and more",
        disableBeacon: true,
        spotlightPadding: 0,
        placement: "top"
    }
]

export default steps;