import styles from "./userSettings.module.css";
import { useEffect } from "react";
import { Alert, Button, Col, Collapse, Form, Input, Popconfirm, Row, Space, Switch, Typography } from "antd";
import rules from "../../helpers/validation/userValidation";
import { userActions } from "../../api/_actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import Icon from '@ant-design/icons';
import { ReactComponent as ExternalLink } from "../../assets/images/externalLink.svg"

function UserSettings() {
    const dispatch = useDispatch();

    const {Text, Link} = Typography

    const [changePasswordForm] = Form.useForm();
    const password = Form.useWatch("password", changePasswordForm);

    const user = useSelector(state => state.authentication.user);
    const userState = useSelector(state => state.users);

    useEffect(() => {
        dispatch(userActions.clearUsers());
    }, [dispatch])

    function changePassword(value) {
        dispatch(userActions.changePassword(password)).then(() => { changePasswordForm.resetFields() });
    }

    function logOut() {
        dispatch(userActions.logout());
    }

    function startGuide(value) {
        dispatch(userActions.updateTourState("startupGuide", !value)).then(() => {
            dispatch(userActions.updateTourState("standardGuide", !value));
        });
    }

    return (
        <div className={ styles.wrapper }>
            <Space size="middle" direction="vertical">
                <div>
                    <Text style={ { display: "block" } }>Version:</Text>
                    <Text>46973160</Text>
                </div>
                

                <Link style={ { display: "block" } } target="_blank" href="/terms-and-conditions">Terms and Conditions <Icon component={ ExternalLink } /></Link>
                <Link style={ { display: "block" } } target="_blank" href="/privacy-policy">Privacy Policy <Icon component={ ExternalLink } /></Link>
                <Link style={ { display: "block" } } target="_blank" rel="noopener noreferrer" href="https://storage.googleapis.com/conlan-downloads/private/usermanual-c1000lp-conlan-configuration-app.html">Quick help <Icon component={ ExternalLink } /></Link>

                <Row gutter={ 20 } justify="space-between" wrap={ false }>
                    <Col>Enable guided tour</Col>
                    <Col><Switch onChange={ startGuide } loading={ userState.updateTourStateRequest } checked={ !user?.tours?.startupGuideCompleted } /></Col>
                </Row>

                <Collapse expandIconPosition="end" items={ [
                    {
                        label: "Change password",
                        children: (
                            <>
                                { (userState.changePasswordSuccess || userState.error) &&
                                    <Alert type={
                                        userState.changePasswordSuccess?.success === false ||
                                            userState.error?.success === false ? "error" : "success"

                                    } message={
                                        userState.changePasswordSuccess?.message ||
                                        userState.error?.message
                                    } />
                                }
                                <Form requiredMark={ false } onFinish={ changePassword } validateTrigger="onSubmit" form={ changePasswordForm }>

                                    <Form.Item rules={ rules.password } name='password' >
                                        <Input.Password placeholder="New password" visibilityToggle />
                                    </Form.Item>

                                    <Form.Item name='repeatPassword' rules={ [...rules.password, () => ({
                                        validator(_, value) {
                                            if (password === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                    ] }>
                                        <Input.Password placeholder="Repeat password" visibilityToggle />
                                    </Form.Item>

                                    <Form.Item style={ { textAlign: "right", marginBottom: 0 } }>
                                        <Button size="middle" type='primary' loading={ userState.changePasswordRequest } htmlType='submit'>Change</Button>
                                    </Form.Item>

                                </Form>
                            </>
                        )
                    }
                ] }>
                </Collapse>

                {/* <Collapse expandIconPosition="end" expandIcon={ () => <Badge count={ data.length } offset={ [15, -15] }><RightOutlined /></Badge> }>
                    <Collapse.Panel header="Notifications" collapsible={ data.length > 0 ? true : "disabled" }>
                        <List style={ { margin: "-16px" } }
                            size="small"
                            dataSource={ [
                                'Test notification 1',
                                'Test notification 2',
                            ] }
                            renderItem={ (item) => <List.Item>{ item }</List.Item> }
                        />
                    </Collapse.Panel>
                </Collapse> */}

            </Space>

            <Popconfirm title="Are you sure you want to log out?" okText="Yes" cancelText="No" placement="bottomLeft" arrow={{pointAtCenter: true}}
                cancelButtonProps={ { size: "middle" } }
                okButtonProps={ { size: "middle" } }
                onConfirm={ logOut }
            >
                <Button type="primary" style={ { marginTop: "40px" } }>
                    Log out
                </Button>
            </Popconfirm>
        </div>
    )
}

export default UserSettings;