export const codeConstants = {
    DELETE_OLD_ONE_TIME_CODES_REQUEST: "DELETE_OLD_ONE_TIME_CODES_REQUEST",
    DELETE_OLD_ONE_TIME_CODES_SUCCESS: "DELETE_OLD_ONE_TIME_CODES_SUCCESS",
    DELETE_OLD_ONE_TIME_CODES_FAILURE: "DELETE_OLD_ONE_TIME_CODES_FAILURE",

    GENERATE_CODE_REQUEST: 'CODES_GENERATE_CODE_REQUEST',
    GENERATE_CODE_SUCCESS: 'CODES_GENERATE_CODE_SUCCESS',
    GENERATE_CODE_FAILURE: 'CODES_GENERATE_CODE_FAILURE',

    UPDATE_USERCODES_REQUEST: "UPDATE_USERCODES_REQUEST",
    UPDATE_USERCODES_SUCCESS: "UPDATE_USERCODES_SUCCESS",
    UPDATE_USERCODES_FAILURE: "UPDATE_USERCODES_FAILURE",

    CLEAR_CODE: 'CLEAR_CODE'
};
